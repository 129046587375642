import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';


const LoginSecondStage = () => {
  const [formData, setFormData] = useState({
    accountName: '',
    province: '',
    status: '',
    dob: '',
  });

  const [accountNameMessage, setAccountNameMessage] = useState('');
  const [showAccountNameInfo, setShowAccountNameInfo] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Proceed');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/login-second-stage', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (response.ok) {
          const userData = await response.json();
          setIsAuthenticated(true);
          setUserName(userData.user.userName);
          setUserSurname(userData.user.userSurname);
  
          // Check if accNameFix is true
          if (userData.user.accNameFix === false) {
            navigate('/login-second-stage');
          } else {
            navigate('/'); // Redirect to homepage if accNameFix is true
          }
        } else {
          setIsAuthenticated(false);
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error checking authentication status:', error.message);
        setIsAuthenticated(false);
      }
    };
  
    checkAuthentication();
  }, [navigate]);  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'accountName') {
      formattedValue = value.replace(/\s/g, '').substring(0, 20);
      setAccountNameMessage('');
      if (!showAccountNameInfo && formattedValue.length > 0) {
        setShowAccountNameInfo(true);
      }
    } else if (name === 'dob') {
      if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(value)) {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (/\s/.test(formData.accountName) || formData.accountName.length > 20) {
      setAccountNameMessage('Account name cannot contain spaces and must be 20 characters or less.');
      return;
    }

    setShowModal(true);
  };

  const handleProceed = async () => {
    setButtonText('Loading...');
    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const { csrfToken } = await csrfResponse.json();

      const response = await fetch('https://lernacy.co.za/api/login-second-stage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setShowModal(false);
        setFormSubmitted(true);
        setButtonText('Redirecting...');
        setTimeout(() => {
          navigate('/');
        }, 3000); // Redirect after 3 seconds
      } else {
        const errorData = await response.json();
        alert(errorData.message);
        setButtonText('Proceed');
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
      setButtonText('Proceed');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleCheck = () => {
    setIsChecked(true);
  };

  return (
    <div className='auth-return-div'>
      <div className='auth-screen-main-div'>
        <div className='color-transition-block'>
          <div className='color-transition'>
            <img src="/styling/logo.png" alt="Lernacy" className='logo-img' />
            <h1>Welcome {userName ? `${userName}` : 'User'}</h1>
            <p>Proceed to complete your account</p>
          </div>
          <img src="/styling/wave-mob.png" alt="Description of the image" className='image-test-block' data-nosnippet/>
          <img src="/styling/Cloudy-large-1.png" alt="Description of the image" className='image-test-block-large' data-nosnippet/>
        </div>

        <div className='form-container'>
          <div className='form-div-box'>
            {isAuthenticated === false && (
              <p style={{ color: 'red' }}>
                You are not authenticated. Please <a href="/login" className='links'>login</a>.
              </p>
            )}

            {isAuthenticated === true && (
              <>
                {accountNameMessage && <p style={{ color: 'red' }}>{accountNameMessage}</p>}

                <p>Complete the information for your account.</p>

                <form onSubmit={handleSubmit} className='login-form-block'>
                  <label>Account Name:</label>
                  <label style={{ color: 'red', textDecoration: 'none' }}>
                    (Once set, Account Name cannot be changed)
                  </label>
                  <br />
                  <input
                    type="text"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleInputChange}
                    maxLength={20}
                    required
                    placeholder="Enter your account name"
                    disabled={formSubmitted}
                  />

                  <label>Date of Birth:</label>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    required
                    disabled={formSubmitted}
                  />
                  <br />

                  <label>Province:</label>
                  <br />
                  <select
                    className='second-stage-select-field'
                    name="province"
                    value={formData.province}
                    onChange={handleInputChange}
                    required
                    disabled={formSubmitted}
                  >
                    <option className='second-stage-option-field' value="">Select province</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                    <option value="Free State">Free State</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="North West">North West</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="Western Cape">Western Cape</option>
                  </select>
                  <br />

                  <label>Status:</label>
                  <br />
                  <select
                    className='second-stage-select-field'
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    required
                    disabled={formSubmitted}
                  >
                    <option value="">Select status</option>
                    <option value="Student">Student</option>
                    <option value="Parent">Parent</option>
                  </select>
                  <label style={{ color: 'red', textDecoration: 'none' }}>
                    (You cannot change your account status after submission.)
                  </label>
                  <br /><br />

                  <button type="submit" disabled={formSubmitted}>
                    {buttonText}
                  </button>
                </form>
              </>
            )}

            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Confirmation</h2>
                  <p style={{ color: '#242526' }}><strong>Note:</strong></p>
                  <ol>
                    <li>Your account name serves as your unique identifier and cannot be changed.</li>
                    <li>Your account status controls what you see on the app and cannot be changed as well.</li>
                    <li>By checking the box below and clicking "Proceed", you acknowledge that you have read 
                      and agree to our <Link to="/terms-of-use" className='login-second-stage-agree'>Terms of Use</Link> and <Link to="/general-disclaimer" className='login-second-stage-agree'>General Disclaimer</Link>.</li>
                  </ol>

                  <div>
                    <label>
                      <input type="checkbox" onChange={handleCheck} checked={isChecked} />
                      I have read and understood the information above.
                    </label>
                  </div>

                  <div>
                    <button onClick={handleProceed} disabled={!isChecked}>
                      {buttonText}
                    </button>
                    <button onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                  
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSecondStage;
