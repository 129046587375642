import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from './MenuToggle';
import './auth.css';

const RequestVerification = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const checkAuthentication = async () => {
    try {
      const response = await fetch('https://lernacy.co.za/api/check-auth', {
        method: 'GET',
        credentials: 'include', // Important for cookies
      });

      if (response.ok) {
        navigate('/');
      } 
    } catch (error) {
      console.error('Error checking authentication status:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Fetch CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Important for cookies
      });
  
      const { csrfToken } = await csrfResponse.json();
  
      // Your form data
      const formData = {
        email,
      };
  
      // Make a POST request to your server
      const response = await fetch('https://lernacy.co.za/api/request-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        body: JSON.stringify(formData),
        credentials: 'include', // Important for cookies
      });
  
      // Handle the response
      const result = await response.json();
  
      if (response.ok) {
        // The request was successful
        setMessage(result.message);
        setError('');
      } else {
        // The request failed
        setMessage('');
        setError(result.error || 'Error requesting verification.');
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error submitting form:', error.message);
      setError('Internal Server Error. Please try again later.');
      setMessage('');
    }
  };

  useEffect(() => {
    // Check authentication status on component mount
    checkAuthentication();
  }, []);

  return (
    <div className='auth-return-div'>
      <div className='auth-screen-main-div'>
        <MenuToggle />

        <div className='color-transition-block'>
          <div className='color-transition'>
              <img src="/styling/logo.png" alt="Lernacy" className='logo-img' /> 
              <h1>Email Verification</h1>   
              <p>Request Email Verification Link</p>    
          </div>
          <img src="/styling/wave-mob.png" alt="Description of the image" className='image-test-block' data-nosnippet/>
          <img src="/styling/Cloudy-large-1.png" alt="Description of the image" className='image-test-block-large' data-nosnippet/>
        </div> 

        <div className='form-container'>          
          <div className='form-div-box'>
            <h3>Submit your email to get verification link</h3>    
            <form onSubmit={handleSubmit} className='login-form-block'>
              <label htmlFor="email">Email:</label> <br />
              <input 
                type="text" 
                id="email" 
                value={email} 
                onChange={handleEmailChange} 
                autoComplete="email"
              />
              {message && <p style={{ color: 'green' }}>{message}</p>}
              {error && <p style={{ color: 'red' }}>{error}</p>}
              
              <button type="submit">Request Verification</button>
            </form>

            
            
            <hr />
            
            <p>
              Already have an account?{' '}
              <Link to="/login" className='links'> Login</Link>
            </p>
            <p>
              Don't have an account?{' '}
              <Link to="/signup" className='links'>Signup</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestVerification;

