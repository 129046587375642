import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'C',
                question4: 'D',
                question5: 'B',
                question6: 'C',
                question7: 'C',
                question8: 'B',
                question9: 'C',
                question10: 'C',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 5,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 5</h2>
            <h3><strong>Lerato's Lesson in Diversity</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img src='/activityImages/ed-support/chantels-journey-of-perspective.jfif' alt='Leratos Lesson in Diversity' />
                </div>
                <div> 
                    <p className='story-p-tag'> 
                        In the heart of a vibrant South African township, lived a young girl named Lerato. She 
                        was curious and eager to learn about the world around her. The township, with its 
                        bustling streets, colorful markets, and lively community events, was a melting pot of 
                        cultures, languages, and traditions. This diversity fascinated Lerato, and she yearned 
                        to understand more about the different people who lived in her community.
                    </p> 
                    <p className='story-p-tag'>
                        One day, Lerato's school announced a community project focused on celebrating the 
                        township's diversity. Each student was to interview someone from a different cultural 
                        background and present their findings. Lerato was excited about the project and decided 
                        to interview Mrs. Naidoo, an elderly Indian woman who ran a popular spice shop in the 
                        market.
                    </p>
                    <p className='story-p-tag'>
                        Mrs. Naidoo was delighted to help. She shared stories of her ancestors who came to 
                        South Africa as indentured laborers in the 19th century, bringing with them rich 
                        traditions and culinary practices. She spoke about Diwali, the festival of lights, and 
                        explained how it symbolized the victory of light over darkness and good over evil. 
                        Lerato was captivated by the colorful descriptions and the deep sense of heritage that 
                        Mrs. Naidoo conveyed.
                    </p>
                    <p className='story-p-tag'>
                        Next, Lerato interviewed Mr. van der Merwe, an Afrikaans farmer who sold fresh produce 
                        at the market. He spoke about the Afrikaner culture, its roots in Dutch, German, and 
                        French Huguenot settlers, and the importance of family and farming in their way of 
                        life. He shared stories of traditional braais (barbecues) and the significance of Rugby 
                        in Afrikaner communities.
                    </p>
                    <p className='story-p-tag'>
                        Lerato also spent time with a Xhosa family who lived nearby. They taught her about the 
                        Xhosa initiation ceremonies, the importance of ancestors, and the beautiful art of 
                        beadwork that adorned their clothing. She learned a few Xhosa phrases and was amazed 
                        at how language could be such a vital part of one's identity.
                    </p>
                    <p className='story-p-tag'>
                        As Lerato compiled her findings, she began to see a beautiful tapestry of cultures, 
                        each contributing to the rich diversity of her township. She presented her project to 
                        her class, highlighting the unique aspects of each culture and the common threads that 
                        bound them together: respect, family, and a sense of community.
                    </p>
                    <p className='story-p-tag'>
                        Her teacher, Mrs. Mokoena, was impressed. "Lerato, you've shown us how diversity is 
                        not just about differences, but about the beauty of coming together and learning from 
                        one another," she said. "Our township is a microcosm of South Africa, a nation with a 
                        complex history but a shared future."
                    </p>
                    <p className='story-p-tag'>
                        Lerato's project inspired her classmates to learn more about their neighbors and 
                        appreciate the diverse heritage of their township. The project culminated in a 
                        community festival where different cultures showcased their music, dance, food, and 
                        traditions. It was a celebration of unity in diversity, a testament to the strength 
                        and beauty that comes from embracing differences.
                    </p>
                    <p className='story-p-tag'>
                        Through this journey, Lerato learned that diversity was not just about understanding 
                        others but also about finding connections and building bridges. She realized that in 
                        the mosaic of her township, every piece, no matter how different, was essential in 
                        creating a harmonious and vibrant community.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What was the main objective of Lerato's school project?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) To learn about South African history
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) To celebrate the diversity in her township
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) To improve the school’s infrastructure   
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) To study the economic impact of local businesses
                        </label>
                    </div>

                    <div>
                        <p>2. Who was the first person Lerato decided to interview for her project?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Mr. van der Merwe
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) A Xhosa family
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Mrs. Naidoo
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Mrs. Mokoena
                        </label>
                    </div>

                    <div>
                        <p>3. What festival did Mrs. Naidoo explain to Lerato?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) Christmas
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) Eid
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) Diwali
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) Hanukkah
                        </label>
                    </div>

                    <div>
                        <p>4. Which cultural group does Mr. van der Merwe belong to?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) Xhosa
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) Zulu
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) Indian
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) Afrikaner
                        </label>
                    </div>

                    <div>
                        <p>5. What traditional activity did Mr. van der Merwe discuss with Lerato?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) Potjiekos cooking
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) Braai (barbecue)
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) Beadwork
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) Gumboot dancing
                        </label>
                    </div>

                    <div>
                        <p>6. What important aspect of Xhosa culture did Lerato learn from the Xhosa family?</p>
                        <label>
                            <input type="radio" name="question6" value="A" onChange={handleChange} />
                            A) The importance of Rugby
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="B" onChange={handleChange} />
                            B) Traditional braais
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="C" onChange={handleChange} />
                            C) Initiation ceremonies
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="D" onChange={handleChange} />
                            D) Spices and culinary practices
                        </label>
                    </div>

                    <div>
                        <p>7. What did Lerato's teacher, Mrs. Mokoena, highlight as a key takeaway from Lerato's project?</p>
                        <label>
                            <input type="radio" name="question7" value="A" onChange={handleChange} />
                            A) The economic growth of the township
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="B" onChange={handleChange} />
                            B) The importance of physical education
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="C" onChange={handleChange} />
                            C) The beauty of coming together and learning from one another
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="D" onChange={handleChange} />
                            D) The history of South African apartheid
                        </label>
                    </div>

                    <div>
                        <p>8. How did Lerato’s project conclude?</p>
                        <label>
                            <input type="radio" name="question8" value="A" onChange={handleChange} />
                            A) With a written report
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="B" onChange={handleChange} />
                            B) With a community festival
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="C" onChange={handleChange} />
                            C) With a class debate
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="D" onChange={handleChange} />
                            D) With a field trip
                        </label>
                    </div>

                    <div>
                        <p>9. What common threads did Lerato find among the different cultures she studied?</p>
                        <label>
                            <input type="radio" name="question9" value="A" onChange={handleChange} />
                            A) Food and music preferences
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="B" onChange={handleChange} />
                            B) Sports and education
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="C" onChange={handleChange} />
                            C) Respect, family, and a sense of community
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="D" onChange={handleChange} />
                            D) Political views and economic status
                        </label>
                    </div>

                    <div>
                        <p>10. What was the overarching lesson Lerato learned through her project?</p>
                        <label>
                            <input type="radio" name="question10" value="A" onChange={handleChange} />
                            A)  The economic benefits of a diverse community
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="B" onChange={handleChange} />
                            B) The importance of political unity
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="C" onChange={handleChange} />
                            C) The value of understanding and embracing diversity
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="D" onChange={handleChange} />
                            D) The history of different South African cultures
                        </label>
                    </div>

                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;