
import React, { useState, useEffect } from 'react';
import {  Link, useParams, useNavigate } from 'react-router-dom';

const SetNewPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    capital: false,
    small: false,
    number: false,
    symbol: false
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Check token validity
    checkTokenValidity(token);
  }, [token]);

  const checkTokenValidity = async (token) => { 
    try {
      const response = await fetch(`https://lernacy.co.za/api/verify-token/${token}`);
      if (response.ok) {
        setIsValidToken(true);
      } else {
        setIsValidToken(false);
        setMessage('Invalid or expired token. Please request a new password reset.');
      }
    } catch (error) {
      console.error('Error checking token validity:', error);
      setIsValidToken(false);
      setMessage('Error checking token validity. Please try again.');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

  const validatePassword = (newPassword, newConfirmPassword) => {
    const validations = {
      length: newPassword.length >= 8,
      capital: /[A-Z]/.test(newPassword),
      small: /[a-z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      symbol: /[^A-Za-z0-9]/.test(newPassword)
    };
    setPasswordValidations(validations);
    if (newPassword === newConfirmPassword) {
      setMessage('Passwords match');
    } else {
      setMessage('Passwords do not match');
    }
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    if (isValidToken && password === confirmPassword) {
      try {
        // Fetch CSRF token from the server
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
          method: 'GET',
          credentials: 'include', // Important for cookies
        });
  
        const { csrfToken } = await csrfResponse.json();
  
        // Make a POST request to your server
        const response = await fetch(`https://lernacy.co.za/api/set-new-password/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken, // Include CSRF token in the headers
          },
          body: JSON.stringify({ password }),
          credentials: 'include', // Important for cookies
        });
  
        const responseData = await response.json();
        if (response.ok) {
          setMessage('Password set successfully! Redirecting...');
  
          // Delay redirection by 4 seconds
          setTimeout(() => {
            // Redirect to the login page
            navigate('/login');
          }, 4000);
        } else {
          console.error('Error updating password:', responseData.message);
          setMessage('Error updating password: ' + responseData.message);
        }
      } catch (error) {
        console.error('Error updating password:', error);
        setMessage('Error updating password. Please try again.');
      }
    } else {
      setMessage('Invalid token or passwords do not match. Please try again.');
    }
  }; 

  const isPasswordValid = Object.values(passwordValidations).every(Boolean);
  const passwordsMatch = password === confirmPassword;

  return (
    <div className='auth-return-div'>
      <div className='auth-screen-main-div'>

        <div className='color-transition-block'>
          <div className='color-transition'>
              <img src="/styling/logo.png" alt="Lernacy" className='logo-img' /> 
              <h1>Reset Password</h1>   
              <p>Reset your password</p>    
          </div>
          <img src="/styling/wave-mob.png" alt="Description of the image" className='image-test-block' data-nosnippet/>
          <img src="/styling/Cloudy-large-1.png" alt="Description of the image" className='image-test-block-large' data-nosnippet/>
        </div> 

        <div className='form-container'> 
          <div className='form-div-box'>
            <h2>Set New Password</h2>

            {message && (
              <p style={{ color: message.includes('error') ? 'green' : 'red' }}>
                {message}
              </p>
            )}

            {isValidToken ? (
              <form onSubmit={handleFormSubmit} className='login-form-block'> 
                <label htmlFor="password">New Password:</label><br />
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                /><br />

                <label htmlFor="confirmPassword">Confirm Password:</label><br />
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                /><br />

                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={handleToggleShowPassword}
                />
                <label htmlFor="showPassword">Show Password</label><br />

                <ul>
                  <li style={{ color: passwordValidations.length ? 'green' : 'red' }}>
                    At least 8 characters long
                  </li>
                  <li style={{ color: passwordValidations.capital ? 'green' : 'red' }}>
                    At least 1 capital letter
                  </li>
                  <li style={{ color: passwordValidations.small ? 'green' : 'red' }}>
                    At least 1 small letter
                  </li>
                  <li style={{ color: passwordValidations.number ? 'green' : 'red' }}>
                    At least 1 number
                  </li>
                  <li style={{ color: passwordValidations.symbol ? 'green' : 'red' }}>
                    At least 1 symbol
                  </li>
                </ul>
                <br />

                <button type="submit" disabled={!isPasswordValid || !passwordsMatch} style={{ backgroundColor: (!isPasswordValid || !passwordsMatch) ? '#aaaaaa' : '#000099', color: (!isPasswordValid || !passwordsMatch) ? '#666666' : '#fff' }}>
                  Set Password
                </button>
              </form>
            ) : (
              <div>
                <p>Invalid or expired token. Please request a new password reset.</p>
              </div>
            )}
            <div>
              <hr />

              <p>
                Proceed to{' '}
                <Link to="/login" className='links'>Login</Link>
              </p>
              <p>
                Request a{' '}
                <Link to="/reset-password" className='links'>password reset link</Link>
              </p>
            </div>

          </div>
        </div> 
      </div>  
    </div>
  );
};

export default SetNewPassword;


/*
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const SetNewPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check token validity
    checkTokenValidity(token);
  }, [token]);

  const checkTokenValidity = async (token) => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/verify-token/${token}`);
      if (response.ok) {
        setIsValidToken(true);
      } else {
        setIsValidToken(false);
        setMessage('Invalid or expired token. Please request a new password reset.');
      }
    } catch (error) {
      console.error('Error checking token validity:', error);
      setIsValidToken(false);
      setMessage('Error checking token validity. Please try again.');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

  const validatePassword = (newPassword, newConfirmPassword) => {
    // Add your password validation logic here
    // For demo purposes, let's check if the password and confirm password match
    if (newPassword === newConfirmPassword) {
      setMessage('Passwords match');
    } else {
      setMessage('Passwords do not match');
    }
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    if (isValidToken && password === confirmPassword) {
      try {
        // Fetch CSRF token from the server
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
          method: 'GET',
          credentials: 'include', // Important for cookies
        });
  
        const { csrfToken } = await csrfResponse.json();
  
        // Make a POST request to your server
        const response = await fetch(`https://lernacy.co.za/api/set-new-password/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken, // Include CSRF token in the headers
          },
          body: JSON.stringify({ password }),
          credentials: 'include', // Important for cookies
        });
  
        if (response.ok) {
          setMessage('Password set successfully! Redirecting...');
  
          // Delay redirection by 4 seconds
          setTimeout(() => {
            // Redirect to the login page
            navigate('/login');
          }, 4000);
        } else {
          setMessage('Error updating password. Please try again.');
        }
      } catch (error) {
        console.error('Error updating password:', error);
        setMessage('Error updating password. Please try again.');
      }
    } else {
      setMessage('Invalid token or passwords do not match. Please try again.');
    }
  };

  return (
    <div className='auth-return-div'>
      <div className='auth-screen-main-div'>

        <div className='color-transition-block'>
          <div className='color-transition'>
              <h1>Reset Password</h1>   
              <p>Reset your password</p>    
          </div>
          <img src="/styling/wave-mob.png" alt="Description of the image" className='image-test-block' />
        </div> 


        <div className='form-container'> 
          <div className='form-div-box'>
            <h2>Set New Password</h2>
            {isValidToken ? (
              <form onSubmit={handleFormSubmit} className='login-form-block'> 
                <label htmlFor="password">New Password:</label><br />
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                /><br /><br />

                <label htmlFor="confirmPassword">Confirm Password:</label><br />
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                /><br /><br />

                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={handleToggleShowPassword}
                />
                <label htmlFor="showPassword">Show Password</label><br /><br />

                <button type="submit">Set Password</button>
              </form>
            ) : (
              <p>Invalid or expired token. Please request a new password reset.</p>
            )}
            {message && <p>{message}</p>}
          </div>
        </div>
      </div>  
    </div>
  );
};

export default SetNewPassword;
*/
