import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccessDenied from '../../components/AccessDenied';

const GrTworepeataddi = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [highScore, setHighScore] = useState(null);
    const [gameScore, setGameScore] = useState(0);
    const [equations, setEquations] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [scoreRating, setScoreRating] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);
    const [gameName] = useState('Grade_2:_Repeated-Addition_Maths_15');

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    const user = data.user;

                    if (!user || !user.accountName) {
                        setIsAuthenticated(false);
                        // Redirect to "/login-second-stage"
                        window.location.href = "/login-second-stage";
                        return;
                    }

                    setUserDetails(user);
                    setIsAuthenticated(true);
                } else {
                    throw new Error('Error fetching user details: ' + response.statusText);
                }
            } catch (error) {
                console.error(error.message);
                setIsAuthenticated(false);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchHighScore();
            generateEquations();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const calculatedPercentage = ((gameScore / 15) * 100).toFixed(2); // Format the percentage to two decimal places
        setPercentage(calculatedPercentage);

        if (calculatedPercentage >= 90) {
            setScoreRating('Outstanding');
        } else if (calculatedPercentage >= 75) {
            setScoreRating('Passed');
        } else if (calculatedPercentage >= 50) {
            setScoreRating('Almost');
        } else {
            setScoreRating('Failed');
        }
    }, [gameScore]);

    const fetchHighScore = async () => {
        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching high score:', error.message);
        }
    };

    const generateEquations = () => {
        const newEquations = [];
        for (let i = 0; i < 15; i++) {
            const numToRepeat = Math.floor(Math.random() * 10) + 1; // Random number to repeat between 1 and 10
            const numRepetitions = Math.floor(Math.random() * 9) + 4; // Random number of repetitions between 2 and 6 for at least one addition
            const equationArray = [];

            // Add the first number to the equation
            equationArray.push(numToRepeat);

            // Add addition operators and numbers to the equation
            for (let j = 0; j < numRepetitions - 1; j++) {
                equationArray.push('+'); // Add an addition operator
                equationArray.push(numToRepeat); // Add the number to repeat
            }

            // Combine the equation array into a string
            const equationString = equationArray.join('');
            const result = eval(equationString); // Evaluate the equation to get the result

            newEquations.push({
                equation: equationString,
                result: result,
                userAnswer: '',
                isCorrect: null,
            });
        }

        setEquations(newEquations);
    };

    const handleAnswerChange = (index, e) => {
        const updatedEquations = [...equations];
        updatedEquations[index].userAnswer = e.target.value;
        setEquations(updatedEquations);
    };

    const handleSubmit = async () => {
        let tempGameScore = 0; // Use a temporary variable to track the score
      
        const newAnswers = equations.map((eq) => {
            const userAnswer = parseInt(eq.userAnswer, 10);
            const isCorrectAnswer = userAnswer === eq.result;
            if (isCorrectAnswer) {
                tempGameScore++;
            }
            return {
                ...eq,
                userAnswer: userAnswer,
                isCorrect: isCorrectAnswer,
            };
        });
      
        setGameScore(tempGameScore); // Update the state with the computed score
        setUserAnswers(newAnswers);
        setIsGameOver(true);
      
        const data = {
            userId: userDetails._id,
            score: tempGameScore, // Use the temporary score variable here
            timeOfDay: new Date().getHours(),
            gameName: gameName,
        };
      
        try {
            // Step 1: Fetch CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
        
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
        
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
      
            // Step 2: Send a POST request with CSRF token
            const response = await fetch('https://lernacy.co.za/api/record-score', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                body: JSON.stringify(data),
                credentials: 'include', // Include credentials
            });
      
            if (response.ok) {
                //console.log('Score recorded successfully!');
            } else {
                console.error('Failed to record score:', response.statusText);
            }
        } catch (error) {
          console.error('Error recording score:', error.message);
        }
    };    

    const restartGame = async () => {
        try {
            setGameScore(0);
            setUserAnswers([]);
            setIsGameOver(false);
            generateEquations();

            // Fetch high score
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error restarting game:', error.message);
        }
    };

    return (
        <div className='tested-activity-wall-container'>
            {isAuthenticated === null ? (
                <p>Loading authentication status...</p>
            ) : !isAuthenticated ? (
                <AccessDenied />
            ) : (
                <div>
                    
                    {!isGameOver ? (
                        <div>
                            <h1 className='activity-name-tag-h1'>G2 - Repeated Addition</h1>
                            <div className='round-high-score'>
                                <h2 className='activity-rounds-tag-h2'>15 Questions</h2>
                                <p>
                                    <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                                </p>
                            </div>
                            <h3>Answer all the questions below:</h3>
                            <div className='equations-aligner-block-cover'>
                                {equations.map((eq, index) => (
                                    <div key={index} className='equations-block-box'>
                                        <p><strong>Question {index + 1}</strong></p>
                                        <p >Solve the equation:<br/> <strong className='equation-p-tag'>{eq.equation} =</strong></p>
                                        <label>Your Answer:</label><br />
                                        <input
                                            value={eq.userAnswer}
                                            onChange={(e) => handleAnswerChange(index, e)}
                                            className='equations-block-box-input'
                                        /><br />
                                    </div>
                                ))}
                            </div>
                            <br/>
                            <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
                        </div>
                    ) : ( 
                        <div>
                            <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore} / 15</h1>
                            
                            <div className='activity-game-over-data'>
                                <h2 className='activity-rounds-tag-h2-left'> Score Rating: {scoreRating}</h2>
                                <h3>{getCommentForScoreRating(scoreRating)}</h3>
                                <p>Percentage: <strong>{percentage}%</strong></p>
                            </div>

                            <div>
                                <h3 className='activity-rounds-tag-h2'>Round Records:</h3>
                                <div className='activity-over-rounds-evidence-ul'> 
                                    {userAnswers.map((answer, index) => (
                                        <div key={index} className='activity-over-rounds-evidence'>
                                            <p key={index}>
                                                <strong>Question {index + 1}</strong> <br /><br />
                                                {answer.equation} = ☐ 
                                                <br />
                                                Your Answer: {answer.userAnswer} {answer.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            <hr />
                            <p>
                                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                            </p>

                            <div className='activity-restart-or-more'>
                                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:1_Mathematics"}>Do More Activities</button>
                            </div>
                            <br/>
                        </div>
                    )}

                    <br />
                </div>
            )}
        </div>
    );
};

const getCommentForScoreRating = (scoreRating) => {
    switch (scoreRating) {
        case 'Outstanding':
            return 'Congratulations! You performed outstandingly.';
        case 'Passed':
            return 'Well done! You passed the counting game.';
        case 'Almost':
            return 'You are close to passing. Keep practicing!';
        case 'Failed':
            return 'Keep practicing. You can do better!';
        default:
            return '';
    }
};

export default GrTworepeataddi;
