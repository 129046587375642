import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages-styling/pages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const IntermediatePhase = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Redirect to login if not authenticated
          if (!data.user) {
            navigate('/login');
          }

          // Redirect to "/login-second-stage" if the user does not have accountName
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const filterSubjects = () => {
      const filtered = [
        { grade: 'Grade 4', subject: 'Mathematics', to: 'Grade:4_Mathematics' },
        // { grade: 'Grade 4', subject: 'Life Skills', to: 'Grade:4_Life-Skills' },
        // { grade: 'Grade 4', subject: 'English FAL', to: 'Grade:4_English-FAL' },
        // { grade: 'Grade 4', subject: 'Technology', to: 'Grade:4_Technology' },
        { grade: 'Grade 4', subject: 'Social Sciences', to: 'Grade:4_Social-Sciences' },
        // { grade: 'Grade 4', subject: 'Natural Sciences', to: 'Grade:4_Natural-Sciences' },

        // { grade: 'Grade 5', subject: 'Mathematics', to: 'Grade:5_Mathematics' },
        // { grade: 'Grade 5', subject: 'Life Skills', to: 'Grade:5_Life-Skills' },
        // { grade: 'Grade 5', subject: 'English FAL', to: 'Grade:5_English-FAL' },
        // { grade: 'Grade 5', subject: 'Technology', to: 'Grade:5_Technology' },
        // { grade: 'Grade 5', subject: 'Social Sciences', to: 'Grade:5_Social-Sciences' },
        // { grade: 'Grade 5', subject: 'Natural Sciences', to: 'Grade:5_Natural-Sciences' },

        // { grade: 'Grade 6', subject: 'Mathematics', to: 'Grade:6_Mathematics' },
        // { grade: 'Grade 6', subject: 'Life Skills', to: 'Grade:6_Life-Skills' },
        // { grade: 'Grade 6', subject: 'English FAL', to: 'Grade:6_English-FAL' },
        // { grade: 'Grade 6', subject: 'Technology', to: 'Grade:6_Technology' },
        // { grade: 'Grade 6', subject: 'Social Sciences', to: 'Grade:6_Social-Sciences' },
        // { grade: 'Grade 6', subject: 'Natural Sciences', to: 'Grade:6_Natural-Sciences' }

        // Add more subjects as needed
      ].filter(subject =>
        subject.grade.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSubjects(filtered);
    };

    filterSubjects();
  }, [searchQuery]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='subjects-by-Grade'>
      {user && user.accountName ? (
        <>
          <h1 className='page-title'>Subjects by Grade</h1>
          <div className='subject-search-input-container'> 
            <input
              type="text"
              placeholder="Search by grade or subject"
              value={searchQuery}
              onChange={handleSearchChange}
              className='subject-search-input'
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className='subject-search-input-icon' size='lg'/>
          </div>

          <div className="flex-container" >
            {filteredSubjects.map(subject =>
              renderSubjectCard(subject.grade, subject.subject, subject.to)
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const renderSubjectCard = (grade, subject, to) => (
  <div className='subjects-container'>
    <div className="subject-card" key={to}>
      <Link className="my-subjects-text" to={`/${to}`}>
        <div className='my-subjects-text-inner'>
          <p className='subject-grade-title-tile'>{`${grade} - ${subject}`}</p>
        </div>
      </Link>
    </div>
  </div>
);

export default IntermediatePhase;


