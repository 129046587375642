import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from './MenuToggle';

const Conferences = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate(); 

  const images = [
    { src: '/styling/communities/lecture.png', text: 'Title 6', link: '#', dateTime: '2080-08-17T14:00:00', province: '', town: '', posterDate: '' },
    { src: '/styling/communities/lecture.png', text: 'Title 5', link: '#', dateTime: '2080-08-18T16:00:00', province: '', town: '', posterDate: '' },
    { src: '/styling/communities/lecture.png', text: 'Title 4', link: '#', dateTime: '2080-08-19T18:00:00', province: '', town: '', posterDate: '' },
    { src: '/styling/communities/lecture.png', text: 'Title 3', link: '#', dateTime: '2080-08-20T20:00:00', province: '', town: '', posterDate: '' },
    { src: '/styling/communities/lecture.png', text: 'Title 2', link: '#', dateTime: '2080-08-21T22:00:00', province: '', town: '', posterDate: '' },
    { src: '/styling/communities/lecture.png', text: 'Title 1', link: '#', dateTime: '2024-08-16T10:25:00', province: 'Gauteng', town: 'Soshanguve South', posterDate: '16 August 2024' },
  ];

  useEffect(() => { 
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    // Update current date-time every second to enable the link when time passes
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const checkLinkStatus = (dateTime) => {
    const targetDateTime = new Date(dateTime);

    if (currentDateTime >= targetDateTime) {
      return { clickable: true, text: '' };
    } else {
      return { clickable: false, text: 'Upcoming' };
    }
  };

  return (
    <div className="oer-page">
      {isLoggedIn ? (
        <div className='not-logged-in-alt'>
          <div className='page-colour-manager'>

            <h1 className='activity-name-tag-h1'>Conferences</h1>
            <div className="my-study-container">  
              <div className='unique-image-card-container-pad'>

                <p className='disclaimer-note-label'>
                  Using this section means you accept our <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                </p>
                <div className="unique-image-card-container">
                  {images.map((image, index) => {
                    const { clickable, text } = checkLinkStatus(image.dateTime);
                    return (
                      <div key={index} className="unique-image-card">
                        <img
                          src={image.src} 
                          alt={image.text}
                          className="unique-image-card-image"
                          onClick={() => openModal(image.src)}
                        />
                        <div className='unique-image-conference-text-container'>
                          <p className="unique-image-conference-text-caption">{image.text}</p>
                          <p className="unique-image-conference-text"><strong>{image.province}, <br /> {image.town}</strong></p>
                          <p className="unique-image-conference-poster-date">{image.posterDate}</p>
                        </div>
                        <div className='unique-image-card-link-container'>
                          <a
                            href={clickable ? image.link : '#'}
                            className="unique-image-card-link"
                            onClick={e => !clickable && e.preventDefault()}
                            style={{
                              color: '#fff',
                              backgroundColor: clickable ? '#000099' : '#333',
                              borderColor: clickable ? '#000099' : '#333',
                              cursor: clickable ? 'pointer' : 'not-allowed',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              padding: '10px 20px', // Adjust padding as needed
                              display: 'inline-block', // Ensure the padding and border are visible
                              textDecoration: 'none', // Remove underline for links
                              borderRadius: '7px',
                            }}
                          >
                            {text}
                          </a>

                        </div>
                      </div>
                    ); 
                  })}

                  {selectedImage && (
                    <div className="unique-image-modal-overlay" onClick={closeModal}>
                      <div className="unique-image-modal-content">
                        <img src={selectedImage} alt="Selected" className="unique-image-modal-image" />
                      </div>
                    </div>
                  )}
                </div>
                
              </div>

            </div> 

          </div>
        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <MenuToggle />
          <div className='not-logged-in-alt-aligner'>
            <h1 className='activity-name-tag-h1'>Conferences</h1>

            <p className='disclaimer-note-label'>
              Using this section means you accept our  
              <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
            </p>
            <div className="unique-image-card-container">
              {images.map((image, index) => {
                const { clickable, text } = checkLinkStatus(image.dateTime);
                return (
                  <div key={index} className="unique-image-card">
                    <img
                      src={image.src} 
                      alt={image.text}
                      className="unique-image-card-image"
                      onClick={() => openModal(image.src)}
                    />
                    <div className='unique-image-conference-text-container'>
                      <p className="unique-image-conference-text-caption">{image.text}</p>
                      <p className="unique-image-conference-text"><strong>{image.province}, <br /> {image.town}</strong></p>
                      <p className="unique-image-conference-poster-date">{image.posterDate}</p>
                    </div>
                    <div className='unique-image-card-link-container'>
                      <a
                        href={clickable ? image.link : '#'}
                        className="unique-image-card-link"
                        onClick={e => !clickable && e.preventDefault()}
                        style={{
                          color: '#fff',
                          backgroundColor: clickable ? '#000099' : '#333',
                          borderColor: clickable ? '#000099' : '#333',
                          cursor: clickable ? 'pointer' : 'not-allowed',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          padding: '10px 20px', // Adjust padding as needed
                          display: 'inline-block', // Ensure the padding and border are visible
                          textDecoration: 'none', // Remove underline for links
                          borderRadius: '7px',
                        }}
                      >
                        {text}
                      </a>

                    </div>
                  </div>
                ); 
              })}

              {selectedImage && (
                <div className="unique-image-modal-overlay" onClick={closeModal}>
                  <div className="unique-image-modal-content">
                    <img src={selectedImage} alt="Selected" className="unique-image-modal-image" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />
        </div>
      )}
    </div>
  );
};

export default Conferences;
