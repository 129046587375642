import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuToggle from '../components/MenuToggle';
import Terms from './Terms';

const TermsOfUse = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => { 
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    // Update current date-time every second to enable the link when time passes
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="oer-page">
      {isLoggedIn ? (
        <div className='not-logged-in-alt'>
          <div className='page-colour-manager'>

            <h1 className='activity-name-tag-h1'>Terms Of Use</h1>
            <div className="my-study-container">  
              <div className='unique-image-card-container-pad'>

                <Terms />
                
              </div>

            </div> 

          </div>
        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <MenuToggle />
          <div className='not-logged-in-alt-aligner'>
            <h1 className='activity-name-tag-h1'>Conferences</h1>

            <Terms />

          </div>

          <br />
        </div>
      )}
    </div>
  );
};

export default TermsOfUse;
