import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AccessDenied from '../../components/AccessDenied';
import '../games/games-css/main-activity.css';

const GrFourBigNumberChallenge = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [highScore, setHighScore] = useState(null);
    const [gameScore, setGameScore] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [scoreRating, setScoreRating] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);
    const [gameName] = useState('Grade_4:_Big-Number_Maths_15');

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    const user = data.user;

                    if (!user || !user.accountName) {
                        setIsAuthenticated(false);
                        // Redirect to "/login-second-stage"
                        window.location.href = "/login-second-stage";
                        return;
                    }

                    setUserDetails(user);
                    setIsAuthenticated(true);
                } else {
                    throw new Error('Error fetching user details: ' + response.statusText);
                }
            } catch (error) {
                console.error(error.message);
                setIsAuthenticated(false);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchHighScore(gameName);
            generateRounds();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const calculatedPercentage = ((gameScore / 15) * 100).toFixed(2); // Format the percentage to two decimal places
        setPercentage(calculatedPercentage);

        if (calculatedPercentage >= 90) {
            setScoreRating('Outstanding');
        } else if (calculatedPercentage >= 75) {
            setScoreRating('Passed');
        } else if (calculatedPercentage >= 50) {
            setScoreRating('Almost');
        } else {
            setScoreRating('Failed');
        }
    }, [gameScore]);

    const fetchHighScore = async (gameName) => {
        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching high score:', error.message);
        }
    };

    const generateRounds = () => {
        const newRounds = [];
        for (let i = 0; i < 12; i++) {
            const firstNumber = Math.floor(Math.random() * 5000) + 1;
            let secondNumber = Math.floor(Math.random() * 5000) + 1;

            while (firstNumber === secondNumber) {
                secondNumber = Math.floor(Math.random() * 30) + 1;
            }

            newRounds.push({ firstNumber, secondNumber, userChoice: '', isCorrect: null });
        }
        setRounds(newRounds);
    };

    const handleUserChoice = (index, choice) => {
        const updatedRounds = [...rounds];
        updatedRounds[index].userChoice = choice;
        setRounds(updatedRounds);
    };

    const handleSubmit = async () => {
        let score = 0;
        const updatedRounds = rounds.map((round) => {
            const isCorrect =
                round.userChoice === '>' ? round.firstNumber > round.secondNumber : round.userChoice === '<' && round.firstNumber < round.secondNumber;
            if (isCorrect) score++;
            return { ...round, isCorrect };
        });
    
        setRounds(updatedRounds);
        setGameScore(score);
        setIsGameOver(true);
    
        const data = {
            userId: userDetails._id,
            score,
            timeOfDay: new Date().getHours(),
            gameName,
        };
    
        try {
            // Fetch CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Send POST request with CSRF token
            const response = await fetch('https://lernacy.co.za/api/record-score', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(data),
                credentials: 'include',
            });
    
            if (response.ok) {
                //console.log('Score recorded successfully!');
            } else {
                console.error('Failed to record score:', response.statusText);
            }
        } catch (error) {
            console.error('Error recording score:', error.message);
        }
    };

    const restartGame = async () => {
        setGameScore(0);
        setUserAnswers([]);
        setIsGameOver(false);
        generateRounds();

        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching high score:', error.message);
        }
    };

    return (
        <div className='tested-activity-wall-container'>
            {isAuthenticated === null ? (
                <p>Loading authentication status...</p>
            ) : !isAuthenticated ? (
                <AccessDenied />
            ) : (
                <div>
                    <h1 style={{ display: 'none' }}>Welcome, {userDetails ? `${userDetails.name} ${userDetails.surname}` : 'User'}!</h1>

                    {!isGameOver ? (
                        <div>
                            <h1 className='activity-name-tag-h1'>G4 - Big Number</h1>
                            <div className='round-high-score'>
                                <h2 className='activity-rounds-tag-h2'>15 Questions</h2>
                                <p>
                                    <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                                </p>
                            </div>
                            <h3>Answer all questions and then submit:</h3>
                            <div className='equations-aligner-block-cover'>
                                {rounds.map((round, index) => (
                                    <div key={index} className='equations-block-box'>
                                        <p><strong>Question {index + 1}</strong></p>
                                        <p>
                                            Which symbol goes between the <br />numbers:<br/>
                                            <strong className='equation-p-tag'>
                                                {round.firstNumber} ☐ {round.secondNumber}
                                            </strong>
                                        </p>
                                        <p className='appropriate-symbol-selector'>Choose an appropriate<br />symbol below</p>
                                        <div className='big-number-challenge-buttons-arrows'>
                                            <button
                                                style={{ backgroundColor: round.userChoice === '<' ? '#000099' : '' }}
                                                onClick={() => handleUserChoice(index, '<')}
                                            >
                                                {'<'}
                                            </button>
                                            <button
                                                style={{ backgroundColor: round.userChoice === '>' ? '#000099' : '' }}
                                                onClick={() => handleUserChoice(index, '>')}
                                            >
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <br/>
                            <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
                        </div>
                    ) : (
                        <div>
                            <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/15</h1>
                            
                            <div className='activity-game-over-data'>
                                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                                <h3>{getCommentForScoreRating(scoreRating)}</h3>
                                <p>Percentage: <strong>{percentage}%</strong></p>
                            </div>
                            
                            <div>
                                <h3 className='activity-rounds-tag-h2'>Round Records:</h3>
                                <div className='activity-over-rounds-evidence-ul'>
                                {rounds.map((round, index) => (
                                    <div key={index} className='activity-over-rounds-evidence'>
                                        <p key={index}>
                                            <strong>Question {index + 1}</strong> <br /><br />
                                            {round.firstNumber} {round.userChoice} {round.secondNumber} {round.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>}
                                        </p>
                                    </div>
                                ))}
                                </div>
                            </div>

                            <hr />
                            <p>
                                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                            </p>

                            <div className='activity-restart-or-more'>
                                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:2_Mathematics"}>Do More Activities</button>
                            </div>
                            <br/>
                        </div>
                    )}


                    <br />
                </div>
            )}
        </div>
    );
};

const getCommentForScoreRating = (scoreRating) => {
    switch (scoreRating) {
        case 'Outstanding':
            return 'Congratulations! You performed outstandingly.';
        case 'Passed':
            return 'Well done! You passed the number challenge.';
        case 'Almost':
            return 'You are close to passing. Keep practicing!';
        case 'Failed':
            return 'Keep practicing. You can do better!';
        default:
            return '';
    }
};

export default GrFourBigNumberChallenge;
