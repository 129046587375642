// SeniorPhase.js
import React from 'react';

const SeniorPhase = () => {
  return (
    <div>
      <h1>Senior Phase</h1>
      <p>This is the content for the Senior Phase.</p>
    </div>
  );
};

export default SeniorPhase;
