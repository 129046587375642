
// src/App.js 

import SignupForm from './components/SignupForm'
import Verify from './components/verify';
import LoginForm from './components/LoginForm';
import LandingPage from './components/LandingPage';
import RequestVerification from './components/RequestVerification';
import Webinars from './components/Webinars';
import Meetings from './components/Meetings';
import ExtraClassesPlatform from './components/ExtraClassesPlatform';
import OERPage from './components/OERPage';
import Articles from './components/Articles';
import Conferences from './components/Conferences';
import ResetPassword from './components/ResetPassword';
import SetNewPassword from './components/SetNewPassword';
import Dashboard from './components/Dashboard';
import UnderConstruction from './components/UnderConstruction';
import ArticlesList from './components/ArticlesList';
import Study from './pages/Study';
import AboutInstitution from './bodyFiles/AboutInstitution';
import TermsOfUse from './pages/TermsOfUse';
import EarlyLearning from './pages/EarlyLearning';
import Contributors from './components/Contributors';
import Communities from './components/Communities';
import Navbar from './components/Navbar';
import LoadingSpinner from './components/LoadingSpinner';
import Copyright from './components/Copyright';
import SensoryPlay from './pages/SensoryPlay';
import AnimalNames from './pages/AnimalNames'; 
import LoginSecondStage from './pages/LoginSecondStage';
import ApplicationPractice from './pages/ApplicationPractice';
import SchoolTest from './pages/SchoolTest';
import MultiModalLearning from './pages/MultiModalLearning';
import PreSchool from './pages/phases/PreSchool';
import FoundationPhase from './pages/phases/FoundationPhase';
import IntermediatePhase from './pages/phases/IntermediatePhase';
import SeniorPhase from './pages/phases/SeniorPhase';
import FetPhase from './pages/phases/FetPhase';
import ProfilePage from './bodyFiles/ProfilePage';
import ImageUploadForm from './bodyFiles/imageUpload';
import SinglePostPage from './bodyFiles/SinglePostPage'
import Accounts from './bodyFiles/Accounts';
import InstitutionsPage from './bodyFiles/InstitutionsPage';
import UserPofiles from './bodyFiles/UserPofiles'
import ViewSchedulePage from './bodyFiles/ViewSchedulePage'
import OpenInstitutionAccounts from './bodyFiles/OpenInstitutionAccounts';
import NotificationPage from './components/notificationsPage';
import UserLinks from './bodyFiles/UserLinks';
import CreateInstitutionSchedule from './bodyFiles/CreateInstitutionSchedule';
import CreateInstitutionAbout from './bodyFiles/CreateInstitutionAbout';
import UserStats from './bodyFiles/UserStats';  
import AdminUsersPage from './bodyFiles/AdminUsersPage';   
import AddSchoolAccountForm from './bodyFiles/AddSchoolAccountForm';   
import AllOnboardInstitutions from './bodyFiles/AllOnboardInstitutions';   

import GradeRMathematics from './pages/classes/GradeRMathematics';
import GradeOneMathematics from './pages/classes/GradeOneMathematics';
import GradeTwoMathematics from './pages/classes/GradeTwoMathematics';
import GradeThreeMathematics from './pages/classes/GradeThreeMathematics';
import GradeFourMathematics from './pages/classes/GradeFourMathematics';

import GradeFourLifeSkills from './pages/classes/GradeFourLifeSkills';
import GradeFourSocialSciences from './pages/classes/GradeFourSocialSciences';

import CountingGame from './pages/games/FoundationPhaseCountingGame';
import GradeOneCountingGame from './pages/games/GrOneCountGame';
import NumberMastermindGame from './pages/games/NumberMastermindGame';
import GrOneNumMastGame from './pages/games/GrOneNumMastGame';
import GrrAddSubGame from './pages/games/GrrAddSubGame';
import GrOneAddSubGame from './pages/games/GrOneAddSubGame';
import GrOneBoxGame from './pages/games/GrOneBoxGame';
import GrOnerepeataddi from './pages/games/GrOnerepeataddi';
import BigNumberChallenge from './pages/games/BigNumberChallenge';
import GrOneNumericFusion from './pages/games/GrOneNumericFusion';
import GrOneSizeShowdown from './pages/games/GrOneSizeShowdown';

import GrTwoAddSubGame from './pages/games/GrTwoAddSubGame';
import GrTwoNumMastGame from './pages/games/GrTwoNumMastGame';
import GrTwoCountingGame from './pages/games/GrTwoCountingGame';
import GrTwoBoxGame from './pages/games/GrTwoBoxGame';
import GrTworepeataddi from './pages/games/GrTworepeataddi';
import GrTwoBigNumberChallenge from './pages/games/GrTwoBigNumberChallenge';
import GrTwoNumericFusion from './pages/games/GrTwoNumericFusion';

import GrThreeAddSubGame from './pages/games/GrThreeAddSubGame';
import GrThreeNumMastGame from './pages/games/GrThreeNumMastGame';
import GrThreeCountingGame from './pages/games/GrThreeCountingGame';
import GrThreeBoxGame from './pages/games/GrThreeBoxGame';
import GrThreerepeataddi from './pages/games/GrThreerepeataddi';
import GrThreeBigNumberChallenge from './pages/games/GrThreeBigNumberChallenge';
import GrThreeNumericFusion from './pages/games/GrThreeNumericFusion';
import GrThreeMultiVide from './pages/games/GrThreeMultiVide';
import GrThreeRoundingOffGame from './pages/games/GrThreeRoundingOffGame';

import GrFourRepeatAddi from './pages/games/GrFourRepeatAddi';
import GrFourNumMastGame from './pages/games/GrFourNumMastGame';
import GrFourAddSubGame from './pages/games/GrFourAddSubGame';
import GrFourBoxGame from './pages/games/GrFourBoxGame';
import GrFourBigNumberChallenge from './pages/games/GrFourBigNumberChallenge';
import GrFourNumericFusion from './pages/games/GrFourNumericFusion';
import GrFourRoundingOffGame from './pages/games/GrFourRoundingOffGame';
import GrFourStrengthSeeker from './pages/gameFiles/IntermediateGames/LifeSkills/GrFourStrengthSeeker';
import GrFourUrbanOdyssey from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourUrbanOdyssey';
import GrFourPathfinderos from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourPathfinderos';
import GrFourMapSkills from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourMapSkills';
import GrFourCardinalPoints from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourCardinalPoints';
import GrFourFoodAndFarming from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourFoodAndFarming';
import GrFourPeopleAndAgriculture from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourPeopleAndAgriculture';
import GrFourWaterUsage from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourWaterUsage';
import GrFourWaterAccessAndControl from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourWaterAccessAndControl';
import TextProcessor from './components/TextProcessor';
import EducationSupport from './pages/education-support/EducationSupport';
import ComprehensionLevels from './pages/education-support/ComprehensionLevels';

import GradeFourSocialSciencesSessionOne from './pages/MultiModalPages/GradeFourSocialSciencesSessionOne';
import GradeFiveSocialSciencesSessionTwo from './pages/MultiModalPages/GradeFiveSocialSciencesSessionTwo';
import GradeEightSocialSciencesSessionThree from './pages/MultiModalPages/GradeEightSocialSciencesSessionThree';

import GeneralDisclaimers from './components/GeneralDisclaimers';
import TopicNotesBlock from './pages/topicNotes/TopicNotesBlock';
import Grade8SocialSciencesUnitOne from './pages/topicNotes/blocks/Grade8SocialSciencesUnitOne';
import Grade9SocialSciencesUnitOne from './pages/topicNotes/blocks/Grade9SocialSciencesUnitOne';

import React, { useState, useEffect } from 'react';  
import { useLocation, BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css'; 
 

function App() {
  return (
    <Router>
      <MainComponent />
    </Router>
  );
}


function MainComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Define an array of paths where you don't want the Copyright component to appear
  const excludedPaths = [
    '/login',
    '/login-second-stage',
    '/signup',
    '/verify/:token',
    '/request-verification',
    '/verify-token/:token',
    '/reset-password',
    '/verify-token/[a-zA-Z0-9._-]+', // Added the new pattern
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simulate page load completion after 2 seconds
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const checkAccNameFix = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/dashboard', {
          withCredentials: true,
        });

        if (response.data.success && response.data.user.accNameFix === false) {
          navigate('/login-second-stage');
        }
      } catch (error) {
        console.error('Error checking accNameFix:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAccNameFix();
  }, [navigate]);


  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="app">
      {loading && <LoadingSpinner />}
        
      <Navbar />  
      
      <Routes>
        
        <Route path="/signup" element={<SignupForm />} />

        
        <Route path="/verify/:token" element={<Verify />} />

        
        <Route path="/login" element={<LoginForm />} />

        <Route path="/Access" element={<LandingPage />} />
        
        <Route path="/request-verification" element={<RequestVerification />} />

        <Route path="/Webinars" element={<Webinars />} />

        <Route path="/Meetings" element={<Meetings />} /> 

        <Route path="/extra-classes" element={<ExtraClassesPlatform />} />

        <Route path="/Open-Educational-Resources" element={<OERPage />} />

        <Route path="/Conferences" element={<Conferences />} />

        <Route path="/articles" element={<Articles />} />

        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/verify-token/:token" element={<SetNewPassword />} />  

        <Route path="/" element={<Dashboard />} />

        <Route path="/site-under-construction" element={<UnderConstruction />} />

        <Route path="/articles" element={<ArticlesList />} />

        <Route path="/study" element={<Study />} />

        <Route path="/institution/:decoyId/about" element={<AboutInstitution />} />

        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="/learning-support" element={<EducationSupport />} />

        <Route path="/comprehension-levels" element={<ComprehensionLevels />} />

        <Route path="/early-learning" element={<EarlyLearning />} />

        <Route path="/contributors" element={<Contributors />} />

        <Route path="/Communities" element={<Communities />} />

        <Route path="/sensory-play" element={<SensoryPlay />} />

        <Route path="/animal-names" element={<AnimalNames />} />

        <Route path="/login-second-stage" element={<LoginSecondStage />} />

        <Route path="/application-practice" element={<ApplicationPractice />} />

        <Route path="/school-tests" element={<SchoolTest />} />

        <Route path="/multimodal-learning" element={<MultiModalLearning />} />

        <Route path="/grade4_social-sciences_session-1" element={<GradeFourSocialSciencesSessionOne />} />

        <Route path="/grade5_social-sciences_session-2" element={<GradeFiveSocialSciencesSessionTwo />} />

        <Route path="/grade8_social-sciences_session-3" element={<GradeEightSocialSciencesSessionThree />} />



        <Route path="/notifications" element={<NotificationPage />} />

        <Route path="/accounts" element={<Accounts />} />

        <Route path="/institutions" element={<InstitutionsPage />} />

        <Route path="/user-stats" element={<UserStats />} />

        <Route path="/Admin-posts-priv" element={<AdminUsersPage />} />

        <Route path="/Add-school-accounts" element={<AddSchoolAccountForm />} />

        <Route path="/All-Onboard-Institutions" element={<AllOnboardInstitutions />} />

        <Route path="/create-schedule" element={<CreateInstitutionSchedule />} />

        <Route path="/institution-about" element={<CreateInstitutionAbout />} />

        <Route path="/user-links" element={<UserLinks />} />

        <Route path="/user/:decoyId" element={<UserPofiles />} /> 

        <Route path="/institution/schedule/:markedDayId" element={<ViewSchedulePage />} />

        <Route path="/institution/:decoyId" element={<OpenInstitutionAccounts />} />

        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/open-post/:postId" element={<SinglePostPage />} />

        <Route path="/upload-Image" element={<ImageUploadForm />} />

        

        <Route path="/pre-school" element={<PreSchool />} />

        <Route path="/foundation-phase" exact element={<FoundationPhase />} />

        <Route path="/intermediate-phase" element={<IntermediatePhase />} />

        <Route path="/senior-phase" element={<SeniorPhase />} />

        <Route path="/fet-phase" element={<FetPhase />} />



        <Route path="/general-disclaimer" element={<GeneralDisclaimers />} />
        <Route path="/topic-notes-block" element={<TopicNotesBlock />} />
        <Route path="/Grade:8_Social-Sciences_Unit1.0" element={<Grade8SocialSciencesUnitOne />} />
        <Route path="/Grade:9_Social-Sciences_Unit1.0" element={<Grade9SocialSciencesUnitOne />} />

        

        <Route path="/Grade:R_Mathematics" element={<GradeRMathematics />} />

        <Route path="/Grade:1_Mathematics" element={<GradeOneMathematics />} />

        <Route path="/Grade:2_Mathematics" element={<GradeTwoMathematics />} />

        <Route path="/Grade:3_Mathematics" element={<GradeThreeMathematics />} />

        <Route path="/Grade:4_Mathematics" element={<GradeFourMathematics />} />

        <Route path="/Grade:4_Life-Skills" element={<GradeFourLifeSkills />} />

        <Route path="/Grade:4_Social-Sciences" element={<GradeFourSocialSciences />} />

        

        <Route path="/counting-game" element={<CountingGame />} />

        <Route path="/number-mastermind" element={<NumberMastermindGame />} />

        <Route path="/Grade-r-AddSubGame" element={<GrrAddSubGame />} />

        

        <Route path="/grade-one-counting-game" element={<GradeOneCountingGame />} />

        <Route path="/grade-one-number-mastermind" element={<GrOneNumMastGame />} />

        <Route path="/Grade-One-AddSubGame" element={<GrOneAddSubGame />} />

        <Route path="/Grade-One-Box-Game" element={<GrOneBoxGame />} />

        <Route path="/Grade-One-Repeated-addition" element={<GrOnerepeataddi />} />

        <Route path="/Big-Number-Challenge" element={<BigNumberChallenge />} />

        <Route path="/Numeric-Fusion:Halve&Double" element={<GrOneNumericFusion />} />

        <Route path="/Size-Showdown" element={<GrOneSizeShowdown />} />

        

        <Route path="/Grade-Two-counting-game" element={<GrTwoCountingGame />} />

        <Route path="/Grade-Two-number-mastermind" element={<GrTwoNumMastGame />} />

        <Route path="/Grade-Two-AddSubGame" element={<GrTwoAddSubGame />} />

        <Route path="/Grade-Two-Box-Game" element={<GrTwoBoxGame />} />

        <Route path="/Grade-Two-Repeated-addition" element={<GrTworepeataddi />} />

        <Route path="/Grade-Two-Big-Number-Challenge" element={<GrTwoBigNumberChallenge />} />

        <Route path="/Grade-Two-Numeric-Fusion:Halve&Double" element={<GrTwoNumericFusion />} />

        

        <Route path="/Grade-Three-counting-game" element={<GrThreeCountingGame />} />

        <Route path="/Grade-Three-number-mastermind" element={<GrThreeNumMastGame />} />

        <Route path="/Grade-Three-AddSubGame" element={<GrThreeAddSubGame />} />

        <Route path="/Grade-Three-Multivide" element={<GrThreeMultiVide />} />

        <Route path="/Grade-Three-Box-Game" element={<GrThreeBoxGame />} />

        <Route path="/Grade-Three-Repeated-addition" element={<GrThreerepeataddi />} />

        <Route path="/Grade-Three-Big-Number-Challenge" element={<GrThreeBigNumberChallenge />} />

        <Route path="/Grade-Three-Numeric-Fusion:Halve&Double" element={<GrThreeNumericFusion />} />

        <Route path="/Grade-Three-Rounding-Off" element={<GrThreeRoundingOffGame />} />

        

        <Route path="/Grade-Four-counting-game" element={<GrThreeCountingGame />} />

        <Route path="/Grade-Four-number-mastermind" element={<GrFourNumMastGame />} />

        <Route path="/Grade-Four-AddSubGame" element={<GrFourAddSubGame />} />

        <Route path="/Grade-Four-Multivide" element={<GrThreeMultiVide />} />

        <Route path="/Grade-Four-Box-Game" element={<GrFourBoxGame />} />

        <Route path="/Grade-Four-Repeated-addition" element={<GrFourRepeatAddi />} />

        <Route path="/Grade-Four-Big-Number-Challenge" element={<GrFourBigNumberChallenge />} />

        <Route path="/Grade-Four-Numeric-Fusion:Halve&Double" element={<GrFourNumericFusion />} />

        <Route path="/Grade-Four-Rounding-Off" element={<GrFourRoundingOffGame />} />

        <Route path="/Grade-Four-Strength-Seeker" element={<GrFourStrengthSeeker />} />

        <Route path="/Grade-Four-Urban-Odyssey" element={<GrFourUrbanOdyssey />} />

        <Route path="/Grade-Four-Pathfinderos" element={<GrFourPathfinderos />} />

        <Route path="/Grade-Four-Map-Skills" element={<GrFourMapSkills />} />

        <Route path="/Grade-Four-Cardinal-Points" element={<GrFourCardinalPoints />} />

        <Route path="/Grade-Four-Food-And-Farming" element={<GrFourFoodAndFarming />} />

        <Route path="/Grade-Four-People-And-Farming" element={<GrFourPeopleAndAgriculture />} />

        <Route path="/Grade-Four-Water-Usage" element={<GrFourWaterUsage />} />

        <Route path="/Water-Access-And-Control" element={<GrFourWaterAccessAndControl />} />

        
        <Route path="/text-processor" element={<TextProcessor />} /> 

        {/* 404 Route - Catch all undefined routes */}
        <Route path="*" element={<NotFoundPage />} />


      </Routes>

      {!excludedPaths.includes(location.pathname) && <Copyright />}
      
    </div>
  );
}

// Example of a simple NotFoundPage component
function NotFoundPage() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
} 

export default App;



/*

// src/App.js 

import SignupForm from './components/SignupForm'
import Verify from './components/verify';
import LoginForm from './components/LoginForm';
import LandingPage from './components/LandingPage';
import RequestVerification from './components/RequestVerification';
import ResetPassword from './components/ResetPassword';
import SetNewPassword from './components/SetNewPassword';
import Dashboard from './components/Dashboard';
import UnderConstruction from './components/UnderConstruction';
import ArticlesList from './components/ArticlesList';
import Study from './pages/Study';
import Terms from './pages/Terms';
import EarlyLearning from './pages/EarlyLearning';
import Contributors from './components/Contributors';
import Navbar from './components/Navbar';
import LoadingSpinner from './components/LoadingSpinner';
import SensoryPlay from './pages/SensoryPlay';
import AnimalNames from './pages/AnimalNames'; 
import LoginSecondStage from './pages/LoginSecondStage';
import ApplicationPractice from './pages/ApplicationPractice';
import SchoolTest from './pages/SchoolTest';
import MultiModalLearning from './pages/MultiModalLearning';
import PreSchool from './pages/phases/PreSchool';
import FoundationPhase from './pages/phases/FoundationPhase';
import IntermediatePhase from './pages/phases/IntermediatePhase';
import SeniorPhase from './pages/phases/SeniorPhase';
import FetPhase from './pages/phases/FetPhase';
import ProfilePage from './bodyFiles/ProfilePage';
import ImageUploadForm from './bodyFiles/imageUpload';
import SinglePostPage from './bodyFiles/SinglePostPage'
import Accounts from './bodyFiles/Accounts';
import InstitutionsPage from './bodyFiles/InstitutionsPage';
import UserPofiles from './bodyFiles/UserPofiles'
import ViewSchedulePage from './bodyFiles/ViewSchedulePage'
import OpenInstitutionAccounts from './bodyFiles/OpenInstitutionAccounts';
import NotificationPage from './components/notificationsPage';
import UserLinks from './bodyFiles/UserLinks';
import CreateInstitutionSchedule from './bodyFiles/CreateInstitutionSchedule';
import UserStats from './bodyFiles/UserStats';  
import AdminUsersPage from './bodyFiles/AdminUsersPage';   
import AddSchoolAccountForm from './bodyFiles/AddSchoolAccountForm';   
import AllOnboardInstitutions from './bodyFiles/AllOnboardInstitutions';   

import GradeRMathematics from './pages/classes/GradeRMathematics';
import GradeOneMathematics from './pages/classes/GradeOneMathematics';
import GradeTwoMathematics from './pages/classes/GradeTwoMathematics';
import GradeThreeMathematics from './pages/classes/GradeThreeMathematics';
import GradeFourMathematics from './pages/classes/GradeFourMathematics';

import GradeFourLifeSkills from './pages/classes/GradeFourLifeSkills';
import GradeFourSocialSciences from './pages/classes/GradeFourSocialSciences';

import CountingGame from './pages/games/FoundationPhaseCountingGame';
import GradeOneCountingGame from './pages/games/GrOneCountGame';
import NumberMastermindGame from './pages/games/NumberMastermindGame';
import GrOneNumMastGame from './pages/games/GrOneNumMastGame';
import GrrAddSubGame from './pages/games/GrrAddSubGame';
import GrOneAddSubGame from './pages/games/GrOneAddSubGame';
import GrOneBoxGame from './pages/games/GrOneBoxGame';
import GrOnerepeataddi from './pages/games/GrOnerepeataddi';
import BigNumberChallenge from './pages/games/BigNumberChallenge';
import GrOneNumericFusion from './pages/games/GrOneNumericFusion';
import GrOneSizeShowdown from './pages/games/GrOneSizeShowdown';

import GrTwoAddSubGame from './pages/games/GrTwoAddSubGame';
import GrTwoNumMastGame from './pages/games/GrTwoNumMastGame';
import GrTwoCountingGame from './pages/games/GrTwoCountingGame';
import GrTwoBoxGame from './pages/games/GrTwoBoxGame';
import GrTworepeataddi from './pages/games/GrTworepeataddi';
import GrTwoBigNumberChallenge from './pages/games/GrTwoBigNumberChallenge';
import GrTwoNumericFusion from './pages/games/GrTwoNumericFusion';

import GrThreeAddSubGame from './pages/games/GrThreeAddSubGame';
import GrThreeNumMastGame from './pages/games/GrThreeNumMastGame';
import GrThreeCountingGame from './pages/games/GrThreeCountingGame';
import GrThreeBoxGame from './pages/games/GrThreeBoxGame';
import GrThreerepeataddi from './pages/games/GrThreerepeataddi';
import GrThreeBigNumberChallenge from './pages/games/GrThreeBigNumberChallenge';
import GrThreeNumericFusion from './pages/games/GrThreeNumericFusion';
import GrThreeMultiVide from './pages/games/GrThreeMultiVide';
import GrThreeRoundingOffGame from './pages/games/GrThreeRoundingOffGame';

import GrFourRepeatAddi from './pages/games/GrFourRepeatAddi';
import GrFourNumMastGame from './pages/games/GrFourNumMastGame';
import GrFourAddSubGame from './pages/games/GrFourAddSubGame';
import GrFourBoxGame from './pages/games/GrFourBoxGame';
import GrFourBigNumberChallenge from './pages/games/GrFourBigNumberChallenge';
import GrFourNumericFusion from './pages/games/GrFourNumericFusion';
import GrFourRoundingOffGame from './pages/games/GrFourRoundingOffGame';
import GrFourStrengthSeeker from './pages/gameFiles/IntermediateGames/LifeSkills/GrFourStrengthSeeker';
import GrFourUrbanOdyssey from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourUrbanOdyssey';
import GrFourPathfinderos from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourPathfinderos';
import GrFourMapSkills from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourMapSkills';
import GrFourCardinalPoints from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourCardinalPoints';
import GrFourFoodAndFarming from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourFoodAndFarming';
import GrFourPeopleAndAgriculture from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourPeopleAndAgriculture';
import GrFourWaterUsage from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourWaterUsage';
import GrFourWaterAccessAndControl from './pages/gameFiles/IntermediateGames/SocialSciences/GrFourWaterAccessAndControl';
import TextProcessor from './components/TextProcessor';
import EducationSupport from './pages/education-support/EducationSupport';
import ComprehensionLevels from './pages/education-support/ComprehensionLevels';

import GradeFourSocialSciencesSessionOne from './pages/MultiModalPages/GradeFourSocialSciencesSessionOne';
import GradeFiveSocialSciencesSessionTwo from './pages/MultiModalPages/GradeFiveSocialSciencesSessionTwo';
import GradeEightSocialSciencesSessionThree from './pages/MultiModalPages/GradeEightSocialSciencesSessionThree';

import React, { useState, useEffect } from 'react'; 
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css'; 

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simulate page load completion after 2 seconds
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app">
      {loading && <LoadingSpinner />}
      
      <Router>
        
        <Navbar />  
        <Routes>

          
          <Route path="/signup" element={<SignupForm />} />

          
          <Route path="/verify/:token" element={<Verify />} />

          
          <Route path="/login" element={<LoginForm />} />

          <Route path="/Access" element={<LandingPage />} />
          
          <Route path="/request-verification" element={<RequestVerification />} />

          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/verify-token/:token" element={<SetNewPassword />} />  

          <Route path="/" element={<Dashboard />} />

          <Route path="/site-under-construction" element={<UnderConstruction />} />

          <Route path="/articles" element={<ArticlesList />} />

          <Route path="/study" element={<Study />} />

          <Route path="/terms-of-use" element={<Terms />} />

          <Route path="/learning-support" element={<EducationSupport />} />

          <Route path="/comprehension-levels" element={<ComprehensionLevels />} />

          <Route path="/early-learning" element={<EarlyLearning />} />

          <Route path="/contributors" element={<Contributors />} />

          <Route path="/sensory-play" element={<SensoryPlay />} />

          <Route path="/animal-names" element={<AnimalNames />} />

          <Route path="/login-second-stage" element={<LoginSecondStage />} />

          <Route path="/application-practice" element={<ApplicationPractice />} />

          <Route path="/school-tests" element={<SchoolTest />} />

          <Route path="/multimodal-learning" element={<MultiModalLearning />} />

          <Route path="/grade4_social-sciences_session-1" element={<GradeFourSocialSciencesSessionOne />} />

          <Route path="/grade5_social-sciences_session-2" element={<GradeFiveSocialSciencesSessionTwo />} />

          <Route path="/grade8_social-sciences_session-3" element={<GradeEightSocialSciencesSessionThree />} />



          <Route path="/notifications" element={<NotificationPage />} />

          <Route path="/accounts" element={<Accounts />} />

          <Route path="/institutions" element={<InstitutionsPage />} />

          <Route path="/user-stats" element={<UserStats />} />

          <Route path="/Admin-posts-priv" element={<AdminUsersPage />} />

          <Route path="/Add-school-accounts" element={<AddSchoolAccountForm />} />

          <Route path="/All-Onboard-Institutions" element={<AllOnboardInstitutions />} />

          <Route path="/create-schedule" element={<CreateInstitutionSchedule />} />

          <Route path="/user-links" element={<UserLinks />} />

          <Route path="/user/:decoyId" element={<UserPofiles />} /> 

          <Route path="/institution/schedule/:markedDayId" element={<ViewSchedulePage />} />

          <Route path="/institution/:decoyId" element={<OpenInstitutionAccounts />} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/open-post/:postId" element={<SinglePostPage />} />

          <Route path="/upload-Image" element={<ImageUploadForm />} />

          

          <Route path="/pre-school" element={<PreSchool />} />

          <Route path="/foundation-phase" exact element={<FoundationPhase />} />

          <Route path="/intermediate-phase" element={<IntermediatePhase />} />

          <Route path="/senior-phase" element={<SeniorPhase />} />

          <Route path="/fet-phase" element={<FetPhase />} />

          

          <Route path="/Grade:R_Mathematics" element={<GradeRMathematics />} />

          <Route path="/Grade:1_Mathematics" element={<GradeOneMathematics />} />

          <Route path="/Grade:2_Mathematics" element={<GradeTwoMathematics />} />

          <Route path="/Grade:3_Mathematics" element={<GradeThreeMathematics />} />

          <Route path="/Grade:4_Mathematics" element={<GradeFourMathematics />} />

          <Route path="/Grade:4_Life-Skills" element={<GradeFourLifeSkills />} />

          <Route path="/Grade:4_Social-Sciences" element={<GradeFourSocialSciences />} />

          

          <Route path="/counting-game" element={<CountingGame />} />

          <Route path="/number-mastermind" element={<NumberMastermindGame />} />

          <Route path="/Grade-r-AddSubGame" element={<GrrAddSubGame />} />

          

          <Route path="/grade-one-counting-game" element={<GradeOneCountingGame />} />

          <Route path="/grade-one-number-mastermind" element={<GrOneNumMastGame />} />

          <Route path="/Grade-One-AddSubGame" element={<GrOneAddSubGame />} />

          <Route path="/Grade-One-Box-Game" element={<GrOneBoxGame />} />

          <Route path="/Grade-One-Repeated-addition" element={<GrOnerepeataddi />} />

          <Route path="/Big-Number-Challenge" element={<BigNumberChallenge />} />

          <Route path="/Numeric-Fusion:Halve&Double" element={<GrOneNumericFusion />} />

          <Route path="/Size-Showdown" element={<GrOneSizeShowdown />} />

          

          <Route path="/Grade-Two-counting-game" element={<GrTwoCountingGame />} />

          <Route path="/Grade-Two-number-mastermind" element={<GrTwoNumMastGame />} />

          <Route path="/Grade-Two-AddSubGame" element={<GrTwoAddSubGame />} />

          <Route path="/Grade-Two-Box-Game" element={<GrTwoBoxGame />} />

          <Route path="/Grade-Two-Repeated-addition" element={<GrTworepeataddi />} />

          <Route path="/Grade-Two-Big-Number-Challenge" element={<GrTwoBigNumberChallenge />} />

          <Route path="/Grade-Two-Numeric-Fusion:Halve&Double" element={<GrTwoNumericFusion />} />

          

          <Route path="/Grade-Three-counting-game" element={<GrThreeCountingGame />} />

          <Route path="/Grade-Three-number-mastermind" element={<GrThreeNumMastGame />} />

          <Route path="/Grade-Three-AddSubGame" element={<GrThreeAddSubGame />} />

          <Route path="/Grade-Three-Multivide" element={<GrThreeMultiVide />} />

          <Route path="/Grade-Three-Box-Game" element={<GrThreeBoxGame />} />

          <Route path="/Grade-Three-Repeated-addition" element={<GrThreerepeataddi />} />

          <Route path="/Grade-Three-Big-Number-Challenge" element={<GrThreeBigNumberChallenge />} />

          <Route path="/Grade-Three-Numeric-Fusion:Halve&Double" element={<GrThreeNumericFusion />} />

          <Route path="/Grade-Three-Rounding-Off" element={<GrThreeRoundingOffGame />} />

          

          <Route path="/Grade-Four-counting-game" element={<GrThreeCountingGame />} />

          <Route path="/Grade-Four-number-mastermind" element={<GrFourNumMastGame />} />

          <Route path="/Grade-Four-AddSubGame" element={<GrFourAddSubGame />} />

          <Route path="/Grade-Four-Multivide" element={<GrThreeMultiVide />} />

          <Route path="/Grade-Four-Box-Game" element={<GrFourBoxGame />} />

          <Route path="/Grade-Four-Repeated-addition" element={<GrFourRepeatAddi />} />

          <Route path="/Grade-Four-Big-Number-Challenge" element={<GrFourBigNumberChallenge />} />

          <Route path="/Grade-Four-Numeric-Fusion:Halve&Double" element={<GrFourNumericFusion />} />

          <Route path="/Grade-Four-Rounding-Off" element={<GrFourRoundingOffGame />} />

          <Route path="/Grade-Four-Strength-Seeker" element={<GrFourStrengthSeeker />} />

          <Route path="/Grade-Four-Urban-Odyssey" element={<GrFourUrbanOdyssey />} />

          <Route path="/Grade-Four-Pathfinderos" element={<GrFourPathfinderos />} />

          <Route path="/Grade-Four-Map-Skills" element={<GrFourMapSkills />} />

          <Route path="/Grade-Four-Cardinal-Points" element={<GrFourCardinalPoints />} />

          <Route path="/Grade-Four-Food-And-Farming" element={<GrFourFoodAndFarming />} />

          <Route path="/Grade-Four-People-And-Farming" element={<GrFourPeopleAndAgriculture />} />

          <Route path="/Grade-Four-Water-Usage" element={<GrFourWaterUsage />} />

          <Route path="/Water-Access-And-Control" element={<GrFourWaterAccessAndControl />} />

          
          <Route path="/text-processor" element={<TextProcessor />} /> 

          
          <Route path="*" element={<NotFoundPage />} />


        </Routes>
      </Router>
    </div>
  );
}

// Example of a simple NotFoundPage component
function NotFoundPage() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
} 

export default App;
*/