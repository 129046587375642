// LoadingSpinner.js

import React from 'react';
import './componentsCSS/LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
      <div className="loading-spinner">
        <img src="/styling/loading2.gif" alt="Loading" className='loading-spinner-gif' data-nosnippet/>
      </div>
    );
    {/**/}
  };
export default LoadingSpinner;
