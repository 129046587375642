import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArticlesCard from './ArticlesCard';
import MenuToggle from './MenuToggle';  
import './Communities.css'; // Import the CSS for styling

const Articles = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const articles = [
    {
      name: 'Community 1',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 1. It provides various resources and support to its members...',
    }, 
    {
      name: 'Community 2',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 2. It offers a platform for discussions and collaborations...',
    }, 
    {
      name: 'Community 3',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 3. Join us to engage in various activities and events...',
    },
    {
      name: 'Community 4',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 4. It’s a place for networking and sharing knowledge...',
    },
    {
      name: 'Community 5',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 5. Be a part of our vibrant community today...',
    },
    {
      name: 'Community 6',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 6. Connect with others who share your interests...',
    },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div className="communities-page">
      {isLoggedIn ? (
        <div className='page-colour-manager'>

            <h1 className='activity-name-tag-h1'>Articles</h1>
            <div className="my-study-container">  
                <div className='unique-image-card-container-pad'>
                  
                  <p className='disclaimer-note-label'>
                    Clicking any link below means you accept our  
                    <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                  </p>


                  <div className="communities-container">
                    {articles.map((community, index) => (
                      <ArticlesCard key={index} community={community} />
                    ))}
                  </div>

                </div>
            </div>

        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <MenuToggle />
          <div className='not-logged-in-alt-aligner'>
            <h1 className='activity-name-tag-h1'>Conferences</h1>

                <p className='disclaimer-note-label'>
                  Clicking any link below means you accept our  
                  <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                </p>


                <div className="communities-container">
                  {articles.map((community, index) => (
                    <ArticlesCard key={index} community={community} />
                  ))}
                </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Articles;
