import React, { useState } from 'react';
import Modal from 'react-modal';

// Set the app element for accessibility (for React Modal)
Modal.setAppElement('#root');

const PlatformLinks = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      {/* YouTube Section */}
      <section>
        <h2>YouTube Videos</h2>
        <div className="link-block" onClick={() => openModal('https://www.youtube.com/embed/oh6pP6EgU_k?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}>
          <p>Watch YouTube Playlist Video</p>
        </div>
      </section>

      {/* Google Drive Section */}
      <section>
        <h2>Google Drive</h2>
        <div className="link-block">
          <a href="https://drive.google.com" target="_blank" rel="noopener noreferrer">Google Drive Resource</a>
        </div>
      </section>

      {/* Facebook Section */}
      <section>
        <h2>Facebook Links</h2>
        <div className="link-block">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook Group</a>
        </div>
      </section>

      {/* Other Platforms */}
      <section>
        <h2>Other Platforms</h2>
        <div className="link-block">
          <a href="https://example.com" target="_blank" rel="noopener noreferrer">Example Resource</a>
        </div>
      </section>

      {/* YouTube Video Modal */}
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            height: 'auto',
          }
        }}
      >
        <button onClick={closeModal}>Close</button>
        <iframe
          width="100%"
          height="450"
          src={currentVideoUrl}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </div>
  );
};

export default PlatformLinks;
