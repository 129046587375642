import React from 'react';

const Copyright = ({ errorMessage }) => {
  return (
    <div className='copyright-text-block-main'>
        <div className='copyright-text-block'>
            <p>
                © 2024 Lernacy. All Rights Reserved.
            </p> 
        </div>
    </div>
  );
};

export default Copyright;