import React, { useState, useEffect, useRef } from 'react';
import './auth.css';
import MenuToggle from './MenuToggle';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faGroupArrowsRotate, faHandHoldingHand,faBars } from '@fortawesome/free-solid-svg-icons';

const LandingPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);


  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setModalOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {  
    if (modalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    }; 
  }, [modalOpen]);

  const openModal = () => {
    setModalOpen(true);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

    return (
        <div className='landing-page-first-div'>
            <MenuToggle />

            <div className='landing-page-block-one-block'>
                <div className='landing-page-block-one-aligner'>
                    <img src="/styling/welcome-1.png" alt="Lernacy" />
                    <div className='landing-page-block-one'>
                        <div className='desktop-logo-container'>
                            <img src="/styling/logo.png" alt="Lernacy" className='desktop-logo' data-nosnippet/>
                        </div>
                        <h1>Experience the future of education</h1>
                        <p>Discover a world of educational activities and resources with Lernacy, the ultimate educational app.</p>
                    </div>
                </div>   
                <div className='get-started-button'>
                    <button className='landing-page-block-one-block-Link-cover' onClick={openModal}>
                        Get Started 
                    </button> 
                </div>
            </div>

            <img src="/styling/wave-mob.png" alt="Splitter" className='image-test-block' data-nosnippet/>
            <img src="/styling/Cloudy-large-1.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

            <div className='landing-page-block-two-block'>
                <h2>
                    Why Choose Lernacy?
                </h2> 

                <div className='landing-page-block-two-block-aligner'>

                    <div className='landing-page-block-two-blockss'>
                        <div className='landing-icon-cont'>
                            <FontAwesomeIcon icon={faSchool} size="3x" className='landing-page-block-two-icon'/>
                        </div>
                        <h3>
                            Affordable for Schools
                        </h3>
                        <p>
                            Lernacy offers an inexpensive solution for educational institutions, ensuring that 
                            schools can access top-notch educational tools without breaking the budget. Our 
                            cost-effective pricing model allows schools to allocate resources efficiently while 
                            enhancing the learning experience for students.
                        </p>
                    </div>

                    <hr />

                    <div className='landing-page-block-two-blockss'>
                        <div className='landing-icon-cont'>
                            <FontAwesomeIcon icon={faGroupArrowsRotate} size="3x" className='landing-page-block-two-icon'/>
                        </div>
                        <h3>
                            Engaged and Open Community
                        </h3>
                        <p>
                            At Lernacy, we believe in the power of community. Our open and inclusive community 
                            fosters collaboration and knowledge sharing among educators, students, and parents. 
                            We engage personally in service rendering, ensuring that every user feels supported 
                            and heard. Join a network where your voice matters and contributes to the collective 
                            growth of everyone involved.
                        </p>
                    </div>

                    <hr />

                    <div className='landing-page-block-two-blockss'>
                        <div className='landing-icon-cont'>
                            <FontAwesomeIcon icon={faHandHoldingHand} size="3x" className='landing-page-block-two-icon'/>
                        </div>
                        <h3>
                            Accurate and Accessible Data
                        </h3>
                        <p>
                            We provide accurate data that empowers educators and students to make informed 
                            decisions. Lernacy is designed to be accessible for daily use, making it easy for 
                            everyone to track progress, identify areas for improvement, and celebrate 
                            achievements. Our user-friendly platform ensures that educational resources and 
                            insights are just a click away, anytime and anywhere.
                        </p>
                    </div>

                    <br />

                </div>

            </div>

            <img src="/styling/section.png" alt="Splitter" className='image-test-block' data-nosnippet/>
            <img src="/styling/section-large.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

            <div className='landing-page-block-three-block'>
                <h2>
                    Services
                </h2>
                <p className='landing-page-p-title'>
                    Check Out Our Service Offerings
                </p>

                <div className='section-three-landing-aligner'>

                    <div className='section-three-landing-box'>
                        <img src="/styling/paths.png" alt="Hero 2" className='section-three-landing' data-nosnippet/>
                        <div>
                            <h3>Personalized Learning Paths</h3>
                            <p>
                                Lernacy offers an inexpensive solution for educational institutions, ensuring that 
                                schools can access top-notch educational tools without breaking the budget. Our 
                                cost-effective pricing model allows schools to allocate resources efficiently while 
                                enhancing the learning experience for students.
                            </p>
                        </div>
                    </div>

                    <hr />

                    <div className='section-three-landing-box'>
                        <img src="/styling/improve.png" alt="Hero 2" className='section-three-landing' data-nosnippet/>
                        <div>
                            <h3>Comprehensive Progress Tracking</h3>
                            <p>
                                Stay informed about your academic progress with our comprehensive tracking tools. 
                                Teachers, students, and parents can easily monitor performance, identify areas 
                                for improvement, and celebrate achievements. Our detailed reports provide 
                                actionable insights to help every learner succeed.
                            </p>
                        </div>
                    </div>

                    <hr />

                </div>

                <div className='section-three-landing-aligner'> 

                    <div className='section-three-landing-box'>
                        <img src="/styling/together.png" alt="Hero 3" className='section-three-landing' data-nosnippet/>
                        <div>
                            <h3>Community Engagement</h3>
                            <p>
                                Join a vibrant community of educators, students, and parents. Our platform fosters 
                                collaboration and support through discussion forums, peer-to-peer learning 
                                opportunities, and community events. Share your knowledge, ask questions, and grow 
                                together with Lernacy.
                            </p>
                        </div>
                    </div>

                    <hr />

                    <div className='section-three-landing-box'>
                        <img src="/styling/access.png" alt="Hero 4" className='section-three-landing' data-nosnippet/>
                        <div>
                            <h3>Accessible Anywhere, Anytime</h3>
                            <p>
                                Learning should be flexible and accessible. With Lernacy, you can access our 
                                resources anytime, anywhere. Whether you're at home, in school, or on the go, 
                                our mobile-friendly platform ensures that you have the tools you need to 
                                succeed right at your fingertips.
                            </p>
                        </div>
                    </div>

                    <hr />

                </div>

            </div>

            <img src="/styling/section-rev.png" alt="Splitter" className='image-test-block' data-nosnippet/>
            <img src="/styling/section-large-rev.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

            <div className='landing-page-block-five-block'>
                <h2>
                    About Us
                </h2>

                <div> 
                    <div className='about-us-section-aligner'>

                        <div>
                            <div className='about-us-section-box'>
                                <h3>Our Mission</h3>
                                <p>
                                    At Lernacy, our mission is to revolutionize education by making high-quality 
                                    learning resources accessible to everyone. We believe that every student deserves 
                                    the opportunity to succeed, and we are dedicated to providing innovative tools 
                                    that empower learners, educators, and parents alike.
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className='about-us-section-box'>
                                <h3>Who We Are</h3>
                                <p>
                                    Lernacy is a team of passionate educators, technologists, and community 
                                    builders committed to transforming the educational landscape. With diverse 
                                    backgrounds and a shared vision, we strive to create an inclusive and 
                                    supportive learning environment for all.
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className='about-us-section-box'>
                                <h3>What We Do</h3>
                                <p>
                                    We offer a comprehensive educational platform that features personalized learning 
                                    paths, interactive activities, and detailed progress tracking. Our goal is to 
                                    enhance the learning experience through engaging and effective resources that cater 
                                    to the unique needs of each student.
                                </p>
                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className='our-values-section-container'>
                        <div className='our-values-section'>
                            <h3>Our Values</h3>
                        
                                <p><strong>Accessibility:</strong><br /> Education should be accessible to everyone, everywhere. Our platform is designed to be user-friendly and available anytime, anywhere.</p>
                                <p><strong>Community:</strong><br /> We believe in the power of community. Our open and engaged community fosters collaboration and support, helping learners and educators thrive together.</p>
                                <p><strong>Affordability:</strong><br /> Quality education shouldn't be expensive. We provide cost-effective solutions that ensure schools can access top-notch educational tools without financial strain.</p>
                                <p><strong>Accuracy:</strong><br /> EWe prioritize providing accurate and actionable data to help learners track their progress and achieve their goals.</p>

                            <div>
                                <h3>Join Us on Our Journey</h3>
                                <p>
                                    Discover a world of educational activities and resources with Lernacy. Join our 
                                    community today and experience the future of education.
                                </p>
                            </div>

                        </div> 
                    </div>
                </div>
            </div>

            <img src="/styling/section.png" alt="Splitter" className='image-test-block' data-nosnippet/>
            <img src="/styling/section-large.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

            <div className='landing-page-block-six-block'>

                <div className='contact-us-section'>
                    <h2>Contact Us</h2>
                    <p>
                        We'd love to hear from you! Whether you have questions, feedback, <br />
                        or need assistance, feel free to reach out to us. Our team is here to help.</p>
                    
                    <div className='contact-details'>
                        <h2>Get in Touch</h2>
                        <p><strong>Email:</strong> admin@lernacy.co.za</p>
                        <p><strong>Phone:</strong> +27 71 169 0833</p>
                        <label><strong>Facebook:</strong></label><a className='landing-page-social-media-links' href='https://www.facebook.com/profile.php?id=61563851974584'>Lernacy</a>
                    </div>

                </div>

            </div>

            <img src="/styling/section-rev.png" alt="Splitter" className='image-test-block' data-nosnippet/>
            <img src="/styling/section-large-rev.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

            <div className='landing-page-block-seven-block'>
                <h2>FAQ</h2>

            </div>

            <div className='testimonial-block-view'>
            
                <img src="/styling/section.png" alt="Splitter" className='image-test-block' data-nosnippet/>
                <img src="/styling/section-large.png" alt="Splitter" className='image-test-block-large' data-nosnippet/>

                <div className='landing-page-block-four-block'>
                    <h2>
                        Testimonials
                    </h2>
                    <p className='landing-page-p-title'>
                        See what people think about us
                    </p>

                    <hr />

                    <div>

                        <div>
                            <div className='testimonial-block'>
                                <p>
                                    <strong>
                                        Lernacy offers an inexpensive solution for educational institutions, ensuring that 
                                        schools can access top-notch educational tools without breaking the budget. Our 
                                        cost-effective pricing model allows schools to allocate resources efficiently while 
                                        enhancing the learning experience for students.
                                    </strong>
                                </p>
                                <div className='testimonials-author'>
                                    <img src="/styling/profile-blank.png" alt="Splitter" className='landing-testimonials' data-nosnippet/>
                                    <div>
                                        <p className='testimonial-author-name'>Author Name</p>
                                        <p className='testimonial-author-occupation'>Author Occupation</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='testimonial-block'>
                                <p>
                                    <strong>
                                        Lernacy offers an inexpensive solution for educational institutions, ensuring that 
                                        schools can access top-notch educational tools without breaking the budget. Our 
                                        cost-effective pricing model allows schools to allocate resources efficiently while 
                                        enhancing the learning experience for students.
                                    </strong>
                                </p>
                                <div className='testimonials-author'>
                                    <img src="/styling/profile-blank.png" alt="Splitter" className='landing-testimonials' data-nosnippet/>
                                    <div>
                                        <p className='testimonial-author-name'>Author Name</p>
                                        <p className='testimonial-author-occupation'>Author Occupation</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='testimonial-block'>
                                <p>
                                    <strong>
                                        Lernacy offers an inexpensive solution for educational institutions, ensuring that 
                                        schools can access top-notch educational tools without breaking the budget. Our 
                                        cost-effective pricing model allows schools to allocate resources efficiently while 
                                        enhancing the learning experience for students.
                                    </strong>
                                </p>
                                <div className='testimonials-author'>
                                    <img src="/styling/profile-blank.png" alt="Splitter" className='landing-testimonials' data-nosnippet/>
                                    <div>
                                        <p className='testimonial-author-name'>Author Name</p>
                                        <p className='testimonial-author-occupation'>Author Occupation</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            

            {modalOpen && (
                <div className='started-modal-overlay'>
                    <div className='started-modal' ref={modalRef}>
                        <button onClick={handleLogin}>Login</button>
                        <button onClick={handleSignup}>Signup</button>
                    </div> 
                </div>
            )}

        </div>
    );
};

export default LandingPage;

