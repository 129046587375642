

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import AccessDenied from '../components/AccessDenied';
import './bodyFiles-styling/bodyFiles.css';
import SomeWentWrong from '../components/SomeWentWrong';

const UserProfiles = () => {
  const { decoyId } = useParams(); 
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null); 
  const [posts, setPosts] = useState([]);
  const [userPosts, setUserPosts] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isBlockedByUser, setIsBlockedByUser] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false); // New state for dropdown

  const formatDateOfBirth = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatDateWithTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/users/${decoyId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(response.status === 401 ? 'Access Denied' : 'Failed to fetch user');
        }

        const data = await response.json();
        setUser(data.user);
        setUserId(data.userId);
        setIsBlockedByUser(data.user.isBlockedByUser); // Set isBlockedByUser flag
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/users/posts/${decoyId}`, { 
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(response.status === 401 ? 'Access Denied' : 'Failed to fetch posts');
        }

        const data = await response.json(); 
        setPosts(data.posts);
        setUserPosts(data.posts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUser();
    fetchPosts();
  }, [decoyId]);

  const handleSupportButton = async (userId, postId) => {
    try { 
        //console.log(userId, postId); // Log userId and postId

        const postIndex = userPosts.findIndex(post => post._id === postId);
        if (postIndex === -1) {
            console.error('Post not found');
            return;
        }

        const updatedUserPosts = [...userPosts];
        const post = updatedUserPosts[postIndex];

        // Toggle support status and update support count locally
        if (post.isSupported) {
            post.supportCount--; // Decrement support count
        } else {
            post.supportCount++; // Increment support count
        }
        post.isSupported = !post.isSupported;

        setUserPosts(updatedUserPosts); // Optimistic update

        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        // Send a POST request to update support count on the backend
        const response = await fetch(`https://lernacy.co.za/api/support/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify({ userId, postId }),
            credentials: 'include', // Include credentials
        });

        if (!response.ok) {
            console.error('Failed to update support count on the server');
            // Revert changes if the request fails
            setUserPosts(userPosts);
            return;
        }

    } catch (error) {
        console.error('Error supporting post:', error);
        // Revert changes if there's an error
        setUserPosts(userPosts);
    }
  };

  const copyPostLinkToClipboard = (postId) => {
    const postLink = `${window.location.origin}/open-post/${postId}`;
    navigator.clipboard.writeText(postLink)
      .then(() => alert('Post link copied to clipboard'))
      .catch((error) => console.error('Failed to copy post link: ', error));
  };

  const toggleAdditionalDetails = () => {
    setShowAdditionalDetails(!showAdditionalDetails);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    if (error === 'Access Denied') {
      return <AccessDenied />;
    }
    return <div>Error: {error}</div>;
  }

  // Conditionally render based on isBlockedByUser flag
  if (isBlockedByUser) {
    return <SomeWentWrong />;
  }

  if (!user) {
    return <div>No user data available</div>;
  }

  return (
    <div className='user-profile-account-aligner-container'>
      
      {user ? (
        <div>
          <h1 className='page-title-name'>User Profile Details</h1>
          <div className='user-profile-account-style-container'>

            

            <div className='user-profile-account-text'>

              
              <div className='profile-short-details-display'>
                <div className='profile-short-details-block'>

                  <div className='user-profile-account-img-container'>
                    <img className='user-profile-account-img' src={user.profilePicture} alt="Profile" />
                  </div>

                  <div className='profile-short-details-texts'>

                    <div>
                      <label className='user-profile-account-text-label'>Name</label>
                      <p className='user-profile-account-text-p'>{user.name || 'Private'} {user.surname || 'Private'}</p>
                    
                      {user.email && (
                        <>
                          <label className='user-profile-account-text-label'>Email</label>
                          <p className='user-profile-account-text-p'>{user.email}</p>
                        </>
                      )}

                      {user.accountName && (
                        <>
                          <label className='user-profile-account-text-label'>Account Name</label>
                          <p className='user-profile-account-text-p'>{user.accountName}</p>
                        </>
                      )}

                      {user.status && (
                        <>
                          <label className='user-profile-account-text-label'>Status</label>
                          <p className='user-profile-account-text-p'>{user.status}</p>
                        </>
                      )}
                    </div>
                    <div>
                      {showAdditionalDetails && (
                        <>
                          {user.dob && (
                            <>
                              <label className='user-profile-account-text-label'>Birth Day</label>
                              <p className='user-profile-account-text-p'>{formatDateOfBirth(user.dob)}</p>
                            </>
                          )}

                          {user.province && (
                            <>
                              <label className='user-profile-account-text-label'>Province</label>
                              <p className='user-profile-account-text-p'>{user.province}</p>
                            </>
                          )}

                          {user.role && (
                            <>
                              <label className='user-profile-account-text-label'>Role</label>
                              <p className='user-profile-account-text-p'>{user.role}</p>
                            </>
                          )}

                          {user.bio && (
                            <>
                              <label className='user-profile-account-text-label'>Bio</label>
                              <p className='user-profile-account-text-p'>{user.bio}</p>
                            </>
                          )}
                        </>
                      )}
                    </div>

                  </div>

                </div>
              </div>

              

            </div>

            
            <div className='more-toggle-button-container'>
              <button className='more-toggle-button' onClick={toggleAdditionalDetails}>
                {showAdditionalDetails ? 'Hide Additional Details' : 'Show Additional Details'}
              </button>
            </div>

          </div>
          
        </div>
      ) : (
        <div>No user data available</div>
      )}

      <br />

      <div className='user-profile-account-posts'>
        {posts.length > 0 ? (
          <ol className="vertical-list">
            {posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((post) => (
              <li key={post._id}>
                <div className='main-post-details'>

                  <div>
                    <Link to={`/user/${decoyId}`} className='user-account-link'> 
                      <div className='single-post-main-block'>

                        <img
                          src={post.author.profilePicture}
                          alt="Profile" className='posts-profileimage'
                        />

                        <div className='author-details-in-posts'>
                          <p className='posts-author-name-surname'>{post.author.name} {post.author.surname}</p> <p className='posts-accountName'>| {post.author.accountName}</p>
                        </div> 

                      </div>

                    </Link>

                    <hr />

                    <Link to={`/open-post/${post._id}`} className="post-link">

                      <p className='post-content-text'>{post.content}</p>
                      
                      <p className='created-at'>{formatDateWithTime(new Date(post.createdAt))}</p>

                    </Link>

                  </div>

                </div>

                <div className='user-profile-posts-interactions'>

                  <Link to={`/open-post/${post._id}`} className="interaction-link-button">
                    {post.commentCount} Comment(s)
                  </Link>

                  <button 
                    onClick={() => handleSupportButton(userId, post._id)} 
                    className={`interaction-link-button ${post.isSupported ? 'supported-button' : 'unsupported-button'}`}
                  >
                    <div className="interaction-link-support-div">
                      {post.supportCount} 
                      <p>Support</p>
                    </div>
                  </button>

                  <button onClick={() => copyPostLinkToClipboard(post._id)} className="interaction-link-button">
                    Share
                    <FontAwesomeIcon icon={faShareNodes} className='fontawesome-icon' />
                  </button>
                  
                </div>  
                
              </li>
            ))}
          </ol>
        ) : (
          <div className='posts-na'>
            No posts available
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfiles;
