import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faNewspaper, faHandHoldingMedical, faHandshake, faPeopleRoof, faPeopleGroup, faSignal, faBoxOpen, faHandHoldingHeart, faCircleNodes, faSignsPost, faSchool, faBookOpen, faUsers, faUser, faBell, faIdCardClip, faBars, faTimes, faRightFromBracket, faUserGroup, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = ({ decoyId }) => { 
  const location = useLocation(); 
  const navigate = useNavigate();
  const [logoutStatus, setLogoutStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to track if the side nav is open or closed
  const navRef = useRef(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]); // State to store notifications
  const [loading, setLoading] = useState(true);
  const [logoutLoading, setLogoutLoading] = useState(false); // State to track logout loading status
  const unreadNotificationsCount = notifications.filter(notification => !notification.read).length;
  const isAdmin = userData && userData.status === 'Admin' && userData.adminValidation; 
  const isAuthenticated = userData !== null; 

  useEffect(() => {
    const fetchNotifications = async () => { 
      try {
        const response = await fetch('https://lernacy.co.za/api/notifications', {
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch notifications');
        }
  
        const data = await response.json();
        // Reverse the notifications before setting the state
        setNotifications(data.notifications.reverse());
        setLoading(false);
  
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setLoading(false);
      }
    };
  
    fetchNotifications();
  }, []);  

  // Other useEffects and functions remain the same

  const toggleNav = () => {
    setIsOpen(!isOpen); // Toggle the state to open/close the side nav
  };
  
  const closeNav = () => {
    setIsOpen(false); // Close the side nav
  };

  const handleClickOutside = (event) => {
    if (isOpen && navRef.current && !navRef.current.contains(event.target)) {
      closeNav();
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleNotification = () => {
    setNotificationOpen(!notificationOpen);
  };

  
  const deleteAllNotifications = async () => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include' // Include credentials for cookies
      });
      const { csrfToken } = await csrfResponse.json();
  
      // Make DELETE request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/notifications/delete-all', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken // Include CSRF token in headers
        },
        credentials: 'include' // Include credentials for cookies
      });
  
      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to delete notifications');
      }
  
      // Clear the notifications state
      setNotifications([]);
  
      alert('All notifications deleted successfully');
    } catch (error) {
      console.error('Error deleting notifications:', error.message);
      alert('Error deleting notifications: ' + error.message);
    }
  };

  const markNotificationsAsRead = async () => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
      
      if (!csrfResponse.ok) {
        throw new Error('Failed to obtain CSRF token');
      }
      
      const { csrfToken } = await csrfResponse.json();
      
      // Make PUT request with CSRF token
      const response = await fetch(`https://lernacy.co.za/api/notifications/mark-read`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        body: JSON.stringify({
          userId: userData._id,
        }),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        // Update the notifications state to mark all as read
        setNotifications(notifications.map(notification => ({
          ...notification,
          read: true,
        })));
        //console.log('Notifications marked as read successfully');
      } else {
        console.error('Error marking notifications as read:', responseData.message);
        alert('Error marking notifications as read: ' + responseData.message);
      }
      
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      alert('Error marking notifications as read');
    }
  };  

  const toggleNotificationRead = async (notificationId) => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Use the CSRF token in the PUT request
      const response = await fetch(`https://lernacy.co.za/api/notifications/${notificationId}/mark-read`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }
  
      // Update the specific notification's read status
      setNotifications(notifications.map(notification => 
        notification._id === notificationId ? { ...notification, read: true } : notification
      ));
  
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => { 
      try {
        const response = await fetch('https://lernacy.co.za/api/navbar', {
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
  
        const data = await response.json();
        setUserData(data.user);
  
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();  
  }, []);
  

  const formatNotificationDate = (dateStr) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Define excluded routes
  const excludedRoutes = [
    '/login',
    '/login-second-stage',
    '/signup',
    '/verify/:token',
    '/request-verification',
    '/verify-token/:token',
    '/reset-password',
    '',
    '/verify-token/[a-zA-Z0-9._-]+', // Added the new pattern
  ];

  // Check if the current route is in the excludedRoutes array
  const shouldRenderNavbar = !excludedRoutes.includes(location.pathname);

  const handleLogout = async () => {
    try {
      setLogoutLoading(true); // Set loading state to true when logout starts

      // Fetch CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Include credentials for the server to identify the user
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const { csrfToken } = await csrfResponse.json();

      // Include CSRF token in the headers of the POST request
      const response = await fetch('https://lernacy.co.za/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        credentials: 'include', // Include credentials for the server to identify the user
      });

      if (response.ok) {
        // Redirect to the login page after successful logout
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Failed to logout');
      }
    } catch (error) {
      // Log and handle errors
      console.error('Error during logout:', error.message);
      // Handle error state appropriately
      // For example, display an error message to the user
      alert('Error during logout: ' + error.message);
    } finally {
      setLogoutLoading(false); // Set loading state to false after logout completes
    }
  };

  // Render the navbar only if shouldRenderNavbar is true
  return shouldRenderNavbar ? (
    <div>
      {/* Overlay */}
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={closeNav}></div>
  
      {/* Render the sidebar-toggle-button only if the user is authenticated */}
      {isAuthenticated && (
        <div>
          <button 
            className='sidebar-toggle-button'
            onClick={toggleNav}>{isOpen ? 
            <FontAwesomeIcon icon={faTimes} /> : 
            <FontAwesomeIcon icon={faBars} className='open-men-icon-bars'/>}
          </button>
        </div>
      )}
      
      {/* Sidebar */}
      {isAuthenticated && (
        <div >
          <div className=''>
            <div className={`sidebar ${isOpen ? 'open' : ''}`} ref={navRef}>
              {/* Sidebar content */}

              <div className='navbar-support-pad-box'></div>

                <div className='user-info-container'>
                    
                  {userData && (
                    <div>
                      <div className="user-info">
                        <img src={userData.profilePicture} alt="Profile" className="user-profile-picture" />
                        <p className='navbar-names-tag'>{userData.name} {userData.surname}</p>
                        <p className='navbar-account-name-tag'>@{userData.accountName}</p>
                      </div>
                    </div>
                  )}

                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'> 
                      <FontAwesomeIcon icon={faHouse} size="lg" /> 
                    </div>
                    Home
                  </Link>
                </div> 
        
                <div className='sidenav-div-container-block'>
                  <Link to="/study" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faBookOpen} size="lg" />
                    </div>
                    Study
                  </Link>
                </div>
        
                <div className='sidenav-div-container-block'>
                  <Link to="/accounts" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faUsers} size="lg" />
                    </div>
                    Accounts
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/institutions" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faCircleNodes} size="lg"/>
                    </div>
                    Institutions
                  </Link>
                </div>

                <div>
                  <Link
                    /*to="/notifications"*/
                    className='sidenav-link-block'
                    onClick={(event) => {
                      closeNav();
                      toggleNotification();
                    }}
                  >
                    <div>
                      <FontAwesomeIcon icon={faBell} className='navbar-icons' size="lg" />
                      {unreadNotificationsCount > 0 && (
                        <span className="notification-count">{unreadNotificationsCount}</span>
                      )}
                    </div>
                    <p className='notifications-name-tag'>Notifications</p>
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/profile" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                    <FontAwesomeIcon icon={faIdCardClip} size="lg"/>
                    </div>
                    Profile
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/user-links" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                    <FontAwesomeIcon icon={faUserGroup} size="lg" />
                    </div>
                    Links
                  </Link>
                </div>  

                <div className='sidenav-div-container-block'>
                  <Link to="/user-stats" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faChartSimple} size="lg"/>
                    </div>
                    Statistics
                  </Link>
                </div>  

                <div className='sidenav-div-container-block'>
                  <Link to="/contributors" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faHandHoldingHeart} size="lg"/>
                    </div>
                    Contributors
                  </Link>
                </div> 

                {isAdmin && (
                  <div className='sidenav-div-container-block'>
                    <Link to="/All-Onboard-Institutions" onClick={closeNav} className='sidenav-link-block'>
                      <div className='sidenav-icons'>
                        <FontAwesomeIcon icon={faSchool} size="lg"/>
                      </div>
                      All Schools
                    </Link>
                  </div>  
                )}

                {isAdmin && (
                  <div className='sidenav-div-container-block'>
                    <Link to="/Add-school-accounts" onClick={closeNav} className='sidenav-link-block'>
                      <div className='sidenav-icons'>
                        <FontAwesomeIcon icon={faSignsPost} size="lg"/>
                      </div>
                      School Account
                    </Link>
                  </div>
                )} 

                <p className='navbar-divider-engage'>---Engage---</p>

                <div className='sidenav-div-container-block'>
                  <Link to="/Open-Educational-Resources" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faBoxOpen} size="lg"/>
                    </div>
                    OER
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/Webinars" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faSignal} size="lg"/>
                    </div>
                    Webinars
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/Meetings" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faHandshake} size="lg"/>
                    </div>
                    Meetings
                  </Link>
                </div>

                <div className='sidenav-div-container-block'>
                  <Link to="/Conferences" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faPeopleRoof} size="lg"/>
                    </div>
                    Conferences
                  </Link>
                </div> 

                <div className='sidenav-div-container-block'>
                  <Link to="/Communities" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faPeopleGroup} size="lg"/>
                    </div>
                    Communities
                  </Link>
                </div> 

                {/*
                <div className='sidenav-div-container-block'>
                  <Link to="/articles" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faNewspaper} size="lg"/>
                    </div>
                    Articles
                  </Link>
                </div>

                
                <div className='sidenav-div-container-block'>
                  <Link to="/Conferences" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faHandHoldingMedical} size="lg"/>
                    </div>
                    Donate
                  </Link>
                </div> 

                
                <div className='sidenav-div-container-block'>
                  <Link to="/profile" onClick={closeNav} className='sidenav-link-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faUsers} size="lg" />
                    </div>
                    Awards
                  </Link>
                </div>  
                */}

                <br />

                <div className='sidenav-div-container-block'>
                  <div onClick={handleLogout} className='sidenav-logout-block'>
                    <div className='sidenav-icons'>
                      <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
                    </div>
                    {logoutLoading ? 'Loading...' : 'Logout'}
                  </div>
                </div>

              {logoutStatus === 'success' && <p className='success-response'></p>}
              {logoutStatus === 'failure' && <p className='error-response'>Error during logout. Please try again.</p>}
              {logoutStatus === 'error' && <p className='error-response'>Internal Server Error. Please try again later.</p>}

            </div>

            <div className='potential-side-menu'>
              <div className='navbar-div-beyond-desktop-container'>
                <div className='navbar-div-beyond-desktop-middle'>
                  <div className='Navbar-div-beyond-desktop'>

                    <div className='navbar-support-pad-box'>

                      <div className='user-info-container'>
                          
                        {userData && (
                          <div>
                            <div className="user-info">
                              <img src={userData.profilePicture} alt="Profile" className="user-profile-picture" />
                              <p className='navbar-names-tag'>{userData.name} {userData.surname}</p>
                              <p className='navbar-account-name-tag'>@{userData.accountName}</p>
                            </div>
                          </div>
                        )}

                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'> 
                            <FontAwesomeIcon icon={faHouse} size="lg" /> 
                          </div>
                          Home
                        </Link>
                      </div> 

                      <div className='sidenav-div-container-block'>
                        <Link to="/study" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faBookOpen} size="lg" />
                          </div>
                          Study
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/accounts" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faUsers} size="lg" />
                          </div>
                          Accounts
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/institutions" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faCircleNodes} size="lg"/>
                          </div>
                          Institutions
                        </Link>
                      </div>

                      <div>
                        <Link
                          /*to="/notifications"*/
                          className='sidenav-link-block'
                          onClick={(event) => {
                            closeNav();
                            toggleNotification();
                          }}
                        >
                          <div>
                            <FontAwesomeIcon icon={faBell} className='navbar-icons' size="lg" />
                            {unreadNotificationsCount > 0 && (
                              <span className="notification-count">{unreadNotificationsCount}</span>
                            )}
                          </div>
                          <p className='notifications-name-tag'>Notifications</p>
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/profile" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                          <FontAwesomeIcon icon={faIdCardClip} size="lg"/>
                          </div>
                          Profile
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/user-links" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                          <FontAwesomeIcon icon={faUserGroup} size="lg" />
                          </div>
                          Links
                        </Link>
                      </div>  

                      <div className='sidenav-div-container-block'>
                        <Link to="/user-stats" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faChartSimple} size="lg"/>
                          </div>
                          Statistics
                        </Link>
                      </div>  

                      <div className='sidenav-div-container-block'>
                        <Link to="/contributors" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faHandHoldingHeart} size="lg"/>
                          </div>
                          Contributors
                        </Link>
                      </div> 

                      {isAdmin && (
                        <div className='sidenav-div-container-block'>
                          <Link to="/All-Onboard-Institutions" onClick={closeNav} className='sidenav-link-block'>
                            <div className='sidenav-icons'>
                              <FontAwesomeIcon icon={faSchool} size="lg"/>
                            </div>
                            All Schools
                          </Link>
                        </div>  
                      )}

                      {isAdmin && (
                        <div className='sidenav-div-container-block'>
                          <Link to="/Add-school-accounts" onClick={closeNav} className='sidenav-link-block'>
                            <div className='sidenav-icons'>
                              <FontAwesomeIcon icon={faSignsPost} size="lg"/>
                            </div>
                            School Account
                          </Link>
                        </div>
                      )} 

                      <p className='navbar-divider-engage'>---Engage---</p>

                      <div className='sidenav-div-container-block'>
                        <Link to="/Open-Educational-Resources" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faBoxOpen} size="lg"/>
                          </div>
                          OER
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/Webinars" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faSignal} size="lg"/>
                          </div>
                          Webinars
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/Meetings" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faHandshake} size="lg"/>
                          </div>
                          Meetings
                        </Link>
                      </div>

                      <div className='sidenav-div-container-block'>
                        <Link to="/Conferences" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faPeopleRoof} size="lg"/>
                          </div>
                          Conferences
                        </Link>
                      </div> 

                      <div className='sidenav-div-container-block'>
                        <Link to="/Communities" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faPeopleGroup} size="lg"/>
                          </div>
                          Communities
                        </Link>
                      </div> 

                      {/*
                      <div className='sidenav-div-container-block'>
                        <Link to="/articles" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faNewspaper} size="lg"/>
                          </div>
                          Articles
                        </Link>
                      </div>


                      <div className='sidenav-div-container-block'>
                        <Link to="/Conferences" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faHandHoldingMedical} size="lg"/>
                          </div>
                          Donate
                        </Link>
                      </div> 


                      <div className='sidenav-div-container-block'>
                        <Link to="/profile" onClick={closeNav} className='sidenav-link-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faUsers} size="lg" />
                          </div>
                          Awards
                        </Link>
                      </div>  
                      */}

                      <br />

                      <div className='sidenav-div-container-block'>
                        <div onClick={handleLogout} className='sidenav-logout-block'>
                          <div className='sidenav-icons'>
                            <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
                          </div>
                          {logoutLoading ? 'Loading...' : 'Logout'}
                        </div>
                      </div>

                      {logoutStatus === 'success' && <p className='success-response'></p>}
                      {logoutStatus === 'failure' && <p className='error-response'>Error during logout. Please try again.</p>}
                      {logoutStatus === 'error' && <p className='error-response'>Internal Server Error. Please try again later.</p>}
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

      <div className={`notification-window ${notificationOpen ? 'open' : ''}`}>
        <button className="close-notification" onClick={toggleNotification}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h3 className='notifications-container-title'>Notifications</h3>
        {loading ? (
          <div>Loading notifications...</div>
        ) : (
          <>
            <div className='notifications-buttons'>
              <button 
                onClick={deleteAllNotifications} 
                className='delete-all-notifications-button'
              >
                Delete All Notifications
              </button>
              <button 
                onClick={markNotificationsAsRead} 
                className='mark-all-as-read'
              >
                
              </button>
            </div>
            <ul className='notifications-list-ul'>
              {notifications.slice().reverse().map(notification => (
                <li key={notification._id} className='Notification-list'>
                  <div onClick={() => toggleNotificationRead(notification._id)} className='Notification-list-block'>
                    <div>
                      <p className='notification-date'>{formatNotificationDate(notification.createdAt)}</p>
                      <hr />
                      {!notification.read ? '(unread)' : (
                        <Link 
                          to={
                            notification.resourceType === 'follow' 
                              ? '/user-links' 
                              : notification.resourceType === 'schedule'
                              ? `/institution/schedule/${notification.resourceId}`
                              : `/open-post/${notification.resourceId}`
                          } 
                          onClick={() => { 
                            closeNav(); 
                            toggleNotification(); 
                          }} 
                          className='notification-link' 
                        >
                          {notification.message}
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/*
            <ul className='notifications-list-ul'>
              {notifications.slice().reverse().map(notification => (
                <li key={notification._id} className='Notification-list'>
                  <div onClick={() => toggleNotificationRead(notification._id)} className='Notification-list-block'>
                    <div>
                      <p className='notification-date'>{formatNotificationDate(notification.createdAt)}</p>
                      <hr />
                      {!notification.read ? '(unread)' : (
                        <Link 
                          to={notification.resourceType === 'follow' ? '/user-links' : `/open-post/${notification.resourceId}`} 
                          onClick={() => { 
                            closeNav(); 
                            toggleNotification(); 
                          }} 
                          className='notification-link' 
                        >
                          {notification.message}
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            */}
          </>
        )}
      </div>

    </div>
  ) : null;
};  

export default Navbar;
