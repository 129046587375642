import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PlatformLinks from './PlatformLinks';
import Modal from 'react-modal';

 
Modal.setAppElement('#root');

const ExtraClassesPlatform = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    const openModal = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setModalIsOpen(true);
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        // Make a request to the server to get user information
        const fetchUser = async () => {
          try {
            const response = await fetch('https://lernacy.co.za/api/study', {
              method: 'GET',
              credentials: 'include', // Include credentials for cross-origin requests
            });
    
            if (response.ok) {
              const data = await response.json();
              setUser(data.user);
    
              // Check if user has an account name
              if (!data.user.accountName) {
                navigate('/login-second-stage');
              }
            } else {
              const errorData = await response.json();
              console.error('Error fetching user data:', errorData.message);
    
              // Redirect to login if not authenticated
              navigate('/login');
            }
          } catch (error) {
            console.error('Error fetching user data:', error.message);
    
            // Redirect to login on error
            navigate('/login');
          }
        };
    
        fetchUser();
      }, [navigate]); // Include navigate in the dependency array

    return (
        <div className='page-colour-manager'>
            {/*
            {user ? (
                <p>Welcome, {user.name} {user.surname}!</p>
                // Add other user information as needed
            ) : (
                <p>Loading user data...</p>
            )}
            */}

            <div>
                <h1 className='activity-name-tag-h1'>Study</h1>
                <div className='extra-class-section-blocks'>
                    {/* YouTube Section */}
                    <section>
                        <h2>Video Streaming</h2>
                        <div className='video-streaming-link-box'>

                            <div className='main-class-collection-container'>
                                <h3>Accounting</h3>
                                <div className='main-class-collection-inner'>

                                    <div className='collection-item-links'>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/1o4p__MY_Ko?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}>
                                            <p>Grade 12 Accounting | Introduction to The Subject by 123tutors</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/bVqBxvkzo9o?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}> 
                                            <p>Grade 12 Accounting | Introduction to Companies (Part 1) by 123tutors</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/bFZP2mr6mX0?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}> 
                                            <p>Grade 12 Accounting | Introduction to Companies (Part 2) by 123tutors</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='main-class-collection-container'>
                                <h3>Social Sciences</h3>
                                <div className='main-class-collection-inner'>

                                    <div className='collection-item-links'>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/oh6pP6EgU_k?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}>
                                            <p>Gr 9 SS History | Term 1 Lesson 1 | An introduction to WW2</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/BiBPIfqhf_w?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr9 History | Term 1 Lesson 3 | Causes of WW2 - Part 2 | The Rise of Hitler</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/ASeFL5u6HYw?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 1| An introduction to the Cold War</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/rHZGrWbYUko?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 2 | Foreknowledge</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/OKeGLVb431Q?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 3 | End of WW2 in Europe</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/OKeGLVb431Q?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 3 | End of WW2 in Europe</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>

                    {/* Google Drive Section */}
                    <section>
                        <h2>Google Drive</h2>
                        <div className="link-block">
                        <a href="https://drive.google.com" target="_blank" rel="noopener noreferrer">Google Drive Resource</a>
                        </div>
                    </section>

                    {/* Facebook Section */}
                    <section>
                        <h2>Facebook Links</h2>
                        <div className="link-block">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook Group</a>
                        </div>
                    </section>

                    {/* Other Platforms */}
                    <section>
                        <h2>Other Platforms</h2>
                        <div className="link-block">
                            <a href="https://example.com" target="_blank" rel="noopener noreferrer">Example Resource</a>
                        </div>
                    </section>

                    {/* YouTube Video Modal */}
                    <Modal 
                        isOpen={modalIsOpen} 
                        onRequestClose={closeModal} 
                        style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            maxWidth: '800px',
                            height: 'auto',
                        }
                        }}
                    >
                        <button onClick={closeModal}>Close</button>
                        <iframe
                        width="100%"
                        height="450"
                        src={currentVideoUrl}
                        title="YouTube video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </Modal>
                </div>
            </div>
            
        </div>
    ); 
};

export default ExtraClassesPlatform;
