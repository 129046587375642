import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages-styling/pages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const FoundationPhase = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Redirect to login if not authenticated
          if (!data.user) {
            navigate('/login');
          }

          // Redirect to "/login-second-stage" if the user does not have accountName
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const filterSubjects = () => {
      const filtered = [
        { grade: 'Grade R', subject: 'Mathematics', to: 'Grade:R_Mathematics' },
        // { grade: 'Grade R', subject: 'Life Skills', to: 'Grade:R_LifeSkills' },
        // { grade: 'Grade R', subject: 'English', to: 'Grade:R_English-FAL' },

        { grade: 'Grade 1', subject: 'Mathematics', to: 'Grade:1_Mathematics' },
        // { grade: 'Grade 1', subject: 'Life Skills', to: 'Grade:1_Life-Skills' },
        // { grade: 'Grade 1', subject: 'English', to: 'Grade:1_English-FAL' },
        // { grade: 'Grade 1', subject: 'Afrikaans', to: 'Grade:1_Afrikaans' },

        { grade: 'Grade 2', subject: 'Mathematics', to: 'Grade:2_Mathematics' },
        // { grade: 'Grade 2', subject: 'Life Skills', to: 'Grade:2_Life-Skills' },
        // { grade: 'Grade 2', subject: 'English', to: 'Grade:2_English-FAL' },
        // { grade: 'Grade 2', subject: 'Afrikaans', to: 'Grade:2_Afrikaans' },

        { grade: 'Grade 3', subject: 'Mathematics', to: 'Grade:3_Mathematics' },
        // { grade: 'Grade 3', subject: 'Life Skills', to: 'Grade:3_Life-Skills' },
        // { grade: 'Grade 3', subject: 'English', to: 'Grade:3_English-FAL' },
        // { grade: 'Grade 3', subject: 'Afrikaans', to: 'Grade:3_Afrikaans' },
        // { grade: 'Grade 3', subject: 'Natural Sciences', to: 'Grade:3_NaturalSciences' }

        // Add more subjects as needed
      ].filter(subject =>
        subject.grade.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSubjects(filtered);
    };

    filterSubjects();
  }, [searchQuery]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='subjects-by-Grade'>
      <div className='subjects-by-Grad-inside'>
        {user && user.accountName ? (
          <>
            <h1 className='page-title'>Subjects by Grade</h1>
            <div className='subject-search-input-container'> 
              <input
                type="text"
                placeholder="Search by grade or subject"
                value={searchQuery}
                onChange={handleSearchChange}
                className='subject-search-input'
              />
              <FontAwesomeIcon icon={faMagnifyingGlass} className='subject-search-input-icon' size='lg'/>
            </div>

            <div className="flex-container" >
              {filteredSubjects.map(subject =>
                renderSubjectCard(subject.grade, subject.subject, subject.to)
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const renderSubjectCard = (grade, subject, to) => (
  <div className='subjects-container'>
    <div className="subject-card" key={to}>
      <Link className="my-subjects-text" to={`/${to}`}>
        <div className='my-subjects-text-inner'>
          <p className='subject-grade-title-tile'>{`${grade} - ${subject}`}</p>
        </div>
      </Link>
    </div>
  </div>
);

export default FoundationPhase;
