import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './bodyFiles-styling/extra-style.css';

const AboutInstitution = () => {
  const [user, setUser] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const { decoyId } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    const fetchInstitutionAbout = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/institution/${decoyId}/about`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
          setMessage('');
        } else {
          const errorData = await response.json();
          console.error('Error fetching InstitutionsAbout data:', errorData.message);
          setMessage('Failed to load institution data.');
        }
      } catch (error) {
        console.error('Error fetching InstitutionsAbout data:', error.message);
        setMessage('Failed to load institution data.');
      }
    };

    fetchUser();
    fetchInstitutionAbout();

  }, [navigate, decoyId]);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 4);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 4) % 4);
  };

  return (
    <div className='page-colour-manager'>
      <h1 className='page-title'>About<br /> {user?.name} {user?.surname}</h1>

      <div className="my-study-container">  
        <div className='meant-to-align'>
          <div className='align-container'>
            <div className='insitution-about-show-block'>
              {loading && <p>Loading...</p>}
              {message && <p>{message}</p>}
              {aboutData && (
                <>
                  <div> 
                    <div className='about-text-blob-container'>
                      <p className='about-text-blob'>{aboutData.aboutText}</p>
                    </div>

                    <div className='carousel-container'>
                      <div className='carousel'>
                        {[...Array(4)].map((_, index) => {
                          const itemIndex = (currentSlide + index) % aboutData.imageCaptions.length;
                          const item = aboutData.imageCaptions[itemIndex];

                          return item ? (
                            <div className='carousel-slide' key={index}>
                              <img src={item.imageUrl} alt={`Caption ${itemIndex}`} />
                              <p>{item.caption}</p>
                            </div>
                          ) : (
                            <div className='carousel-slide' key={index}>
                              <p>No image available</p>
                            </div>
                          );
                        })}
                      </div>
                      <button onClick={handlePrevSlide} className='carousel-control prev'>◀</button>
                      <button onClick={handleNextSlide} className='carousel-control next'>▶</button>
                    </div>

                  </div>

                  <hr />

                  <h2 className='achievement-h2'>Achievements:</h2>
                  <div className='about-achievements-list-block'>
                    <ul >
                      {aboutData.achievements.split(',').map((achievement, index) => (
                        <li key={index}>{achievement.trim()}</li>
                      ))}
                    </ul>
                  </div>
                  <br />

                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInstitution;



