import React, { useState, useEffect } from 'react';
import AccessDenied from './AccessDenied';
import SomeWentWrong from './SomeWentWrong';

const articlesData = [
  {
    title: 'Article 1',
    author: 'Author 1',
    image: 'image-url-1',
    link: 'https://example.com/article-1',
    description: 'Description for Article 1'
  },
  {
    title: 'Article 2',
    author: 'Author 2',
    image: 'image-url-2',
    link: 'https://example.com/article-2',
    description: 'Description for Article 2'
  },
  // Add more articles as needed
];

const ArticlesList = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials in request
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Unauthorized'); // Throw error for 401 status
          } else if (response.status === 403) {
            throw new Error('Forbidden'); // Throw error for 403 status
          } else {
            throw new Error('Network response was not ok');
          }
        }

        setIsLoading(false); // Set loading state to false
      } catch (error) {
        setError(error); // Set error state if request fails
        setIsLoading(false); // Set loading state to false
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures effect runs only once

  // Render loading spinner or error message if loading
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render AccessDenied component if user is not authenticated (401)
  if (error && error.message === 'Unauthorized') {
    return <AccessDenied />;
  }

  // Render SomeWentWrong component for other errors (403 or network errors)
  if (error) {
    return <SomeWentWrong />;
  }

  // Render contributors list if no error
  return (
    <div className='activity-wall-container'>

        <h1 className='activity-name-tag-h1'>Contributors</h1>
        <div>
          <h1>Articles</h1>
          {articlesData.map((article, index) => (
            <div key={index}>
              <img src={article.image} alt={article.title} />
              <h2>{article.title}</h2>
              <p>Author: {article.author}</p>
              <p>{article.description}</p>
              <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
            </div>
          ))}
        </div>
        <br />

    </div> 
  );
};

export default ArticlesList;